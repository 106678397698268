import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import DesktopApp from './components/desktopComponents/DesktopApp';
import Datenschutz from './components/desktopComponents/footer/datenschutz/Datenschutz';
import Impressum from './components/desktopComponents/footer/impressum/Impressum';
import PressPage from './components/desktopComponents/press/Press';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {index: true, element: <DesktopApp />}
    ]
  },
  {
    path: '/datenschutz',
    element: <Datenschutz />
  },
  {
    path: '/impressum',
    element: <Impressum />
  },
  {
    path: '/press',
    element: (
      <div>
   
        <PressPage />

      </div>
    )
  }
])
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RouterProvider router={router}>
    <App />
  </RouterProvider>
)


