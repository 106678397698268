import React from "react";
import "./statusBar.css";

export default function StatusBar(props) {
  const getStatusText = () => {
    if (props.mainCheck === true) {
      if (props.language === 'DE') return "Sorry, zurzeit kannst du nicht laden"
      else return "Sorry, no charging at the moment"
    }
    else {
      if (props.language === "DE") {
      return props.status
        ? "Du kannst jetzt laden "
        : "Sorry, zurzeit kannst du nicht laden"
    }
    return props.status ? "You can charge now" : "Sorry, no charging at the moment"
    }
  }

  return (
    <div>
    {props.mainCheck === true &&(
      <div className={`status-bar-text false`}>
        <div className="div-status">
        <p className="text-status">{getStatusText()} </p>
        <img
          src="/Icons/3x/icon_clock.svg"
          alt="Not Found"
          className="usmile-squint-wink-alt"
        />
      </div>
    </div>
    )}
    {props.mainCheck === false && (
      <div className={`status-bar-text ${props.status}`}>
        {props.status ? (
          <div className="div-status">
            <p className="text-status">{getStatusText()} </p>
            <img
              src="/Icons/3x/Vector-true.svg"
              alt="Not Found"
              className="usmile-squint-wink-alt"
            />
          </div>
        ) : (
          <div className="div-status">
            <p className="text-status">{getStatusText()} </p>
            <img
              src="/Icons/3x/icon_clock.svg"
              alt="Not Found"
              className="usmile-squint-wink-alt"
            />
          </div>
        )}
      </div>
    )}
    
    </div>
  );
}
