import React, { useState, useEffect } from "react"
import Modal from "react-modal"
import axios from "axios"



const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

Modal.setAppElement('#root');


export default function SendFeedback(props) {
  const [feedbackReceived, setFeedbackReceived] = useState(false)
  const getText = (key) =>{
    if (props.language === 'DE'){
      switch (key){
        case 'received':
          return 'Feedback erhalten!'
      }
    }
    switch(key){
      case 'received':
        return 'Feedback received!'
    }
  }

  useEffect(() => {
    const sendFeedback = async () => {
      const url = 'api/pilot/feedback'
      const data = {
        rate: props.rate,
        feedback: props.feedback, 
      }
      try {
        const response = await axios.post(url, data);
        console.log(response.data);
        setFeedbackReceived(true);
        props.resetForm();
      } catch (error) {
        console.log(error);
      }
    };

    sendFeedback()
  }, [props.feedback])

  return (
    <div>
      {feedbackReceived && <p>{getText('received')}</p>}
    </div>
  );
}
