import "./hero.css"
import { useState } from "react";
import GiveFeedback from "../feedback/GiveFeedback"
import RegistrationButton from "../registration/RegistrationButton"
import RegistrationForm from "../registration/RegistrationForm";
import RegisterUser from "../requests/RegisterUse";

export default function Hero({scrollToFeedbackSection, language, registrationProp, availableDates}) {
  const [registration, setRegistration] = useState(registrationProp|| false);
  const [registrationData, setRegistrationData] = useState(false)
  const isMobile = window.innerWidth <=767
  const isIpad = window.innerWidth >= 768 && window.innerWidth < 1100

  const handleRegistrationChange = (value) => {
    setRegistration(value);
  }
  const handleRegistrationData = (value) =>{
    console.log('data is received in Parent', value)
    setRegistrationData(value)
  }

  const getText = (key) =>{
    if (language === 'DE'){
      switch (key) {
        case 'welcome':
          return `WILLKOMMEN!`
        case 'welcome2':
          return 'Schließe dich unserer Mission an, Ladestationen mit '
        case 'energy':
          return 'mobiler Energie zu betreiben!'
        case 'becoming':
          return 'Wenn du Testnutzer*in wirst, hast du Zugang zu:'
        case 'list1':
          return 'einem attraktiven Angebot zum Schnellladen deines Autos auf dem 24-Autohof Lutterberg.'
        case 'list2':
          return 'Zugang zu 100% mobilier Energie.'
        case 'list3':
          return 'Zukünftigen Vorteilen als Teil der GME Charging-Community.'
        case 'miss':
          return 'Lass dir diese einmalige Gelegenheit nicht entgehen, die Zukunft der nachhaltigen Mobilität mitzugestalten!'
      }
    }
      switch (key){
        case 'welcome':
          return 'WELCOME!'
        case 'welcome2':
          return 'Join our mission to provide charging services with '
        case 'energy':
          return 'mobile energy!'
        case 'becoming':
          return 'By becoming a test user, you will have access to:'
        case 'list1':
          return 'An attractive offer to fast charge your car at 24-Autohof Lutterberg.'
        case 'list2':
          return 'Access to 100% local renewable energy.'
        case 'list3':
          return 'Receive future benefits as part of the GME Charging community.'
        case 'miss':
          return 'Do not miss out on this unique opportunity to play a vital role in shaping the future of sustainable mobility!'
      }
    
  }


  return (
    <div>
    <div className="hero-main" id="heroSection">
      {/* <div></div> */}
      {
        isMobile && (
          <div className="mobile-image">
            <img src='/Images/heroimage1505x610.jpg' alt="heroI"/>
            </div>
        )
      }
      <div className="image-container-hero">
        {!isMobile&& !isIpad && <div className="background-image" />}
        {isIpad && <div className="backgroung-ipad"/>}
      </div>
     
      <div className="hero">
        

        <div >
          <p className="welcome-join-our-missio">
              <span className='label1'>{getText('welcome')}  </span>
              <br />
              <span className="label1">{getText('welcome2')}</span>
              <span className='label2'>{getText('energy')}</span>
          </p>
        </div>
        <div className='by-becoming-atest-user-y'>
          <div className='textBlock'>{getText('becoming')} </div>
          <ul className='list'>
            <li>
                {getText('list1')}
            </li> 
            <li>
                {getText('list2')}
            </li>
            <li>
              {getText('list3')}
            </li>
          </ul>
        </div>
        <div className='by-becoming-atest-user-y'>
          <div className="textBlock">
            {getText('miss')}
          </div>
        </div>

        
        <div className="click-area-buttons-hero">
          <RegistrationButton  onRegistrationChange={handleRegistrationChange} registration={registration} language={language} availableDates={availableDates} />
          <GiveFeedback scrollToFeedbackSection = {scrollToFeedbackSection} language={language}/>
        </div>
      </div>
      
    </div>
    <div>      
    {((registrationProp === true || registration===true)&&(availableDates.length !==0)) &&
      <div id="registration">
        <RegistrationForm onCancel = {handleRegistrationChange} onSend = {handleRegistrationData} language={language} availableDates={availableDates}/>
      </div>
    }
    {registrationData && <RegisterUser {...registrationData}/>}
  </div>
  </div>
  )
}
