import React, { useState, useEffect } from "react"
import "./feedback.css"
import TextareaAutosize from 'react-textarea-autosize';
import SendFeedback from "./SendFeedback";


export default function SectionFeedback({language}) {
  const [feedback, setFeedback] = useState('')
  const [sendFeedback, setSendFeedback] = useState(false)
  const [rate, setRate] = useState(null)
  const [captchaValue, setCaptchaValue] = useState("")


  const handleFeedbackChange = (event) =>{
    setFeedback(event.target.value)
  }
  const handleFeedbackSend = ()=>{
    setSendFeedback(true)

  }
  const resetForm = () =>{
    setFeedback('')
    setSendFeedback(false)
  }
  const handleRateClick = (value) => {
    setRate(value);
  };
  const getText = (key) =>{
    if (language === 'DE'){
      switch (key){
        case 'opinion':
          return 'Deine Meinung zählt!'
        case 'satisfaction':
          return 'Wie zufrieden bist du mit unserem Service?'
        case 'text':
          return 'Bitte erzähl uns mehr'
        case 'captcha':
          return 'ReCAPTCHA'
        case 'thank':
          return 'Vielen Dank für dein Feedback!'
        case 'send':
          return 'Feedback senden'
        case 'satisfied':
          return 'Hervorragend'
        case 'midSatis':
          return 'Befriedigend'
        case 'dissappointed':
          return 'Enttäuscht'
      }
    }
    switch (key) {
      case 'opinion':
        return 'Your opinion matters!'
      case 'satisfaction':
        return 'How satisfied are you with our service?'
      case 'text':
        return 'Please, tell us more'
      case 'captcha':
        return 'ReCAPTCHA'
      case 'thank':
        return 'Thank you for sharing!'
      case 'send':
        return 'Send feedback'
      case 'satisfied':
        return 'Very Satisfied'
      case 'midSatis':
        return 'OK'
      case 'dissappointed':
        return 'Dissappointed'

    }
  }
  useEffect(() => {
    const scriptCaptcha = document.createElement("script")
    scriptCaptcha.src = "https://www.google.com/recaptcha/api.js"
    document.body.appendChild(scriptCaptcha)
  
    const handleSubmit = (event) => {
      event.preventDefault()
      const form = event.target
      const captchaValue = form.querySelector(".g-recaptcha-response").value
      setCaptchaValue(captchaValue)
    };
  
    const form = document.querySelector("#g-recaptcha")
    form.addEventListener("submit", handleSubmit);
  
    return () => {
      form.removeEventListener("submit", handleSubmit)
    }
  }, [])
  
  return (
    <div className="section-feedback">
      <div className="overall-feedback-text">
        <p className="your-opinion-matters-ho">
           {getText('opinion')}
        </p>
        <p className="how-satisfied">
          {getText('satisfaction')}
        </p>
        <div className="icons-location">
          <div className="icons-container" onClick={()=> handleRateClick(1)}>
            <img
              src="/Icons/3x/u_sad.svg"
              alt="Icon 1"
              className={`icon ${rate === 1 ? 'selected sad' : ''}`}
            />

            {rate === 1 && <span className="rate-text">{getText('dissappointed')}</span>}
          </div>
          <div className="icons-container" onClick={()=> handleRateClick(2)}>
            <img
              src="/Icons/3x/u_meh.svg"
              alt="Icon 2"
              className={`icon ${rate === 2 ? 'selected meh' : ''}`}
            />
            {rate === 2 && <span className="rate-text">{getText('midSatis')}</span>}
          </div>  
            
          <div className="icons-container" onClick={()=> handleRateClick(3)}>
            <img
              src="/Icons/3x/u_smile-beam.svg"
              alt="Icon 3"
              className={`icon ${rate === 3 ? 'selected happy' : ''}`}
            />
            {rate === 3 && <span className="rate-text">{getText('satisfied')}</span>}

          </div>
        </div>     
      </div>
     
      <div className="tell-us-more">
        <p className="label-text">{getText('text')}</p>
        <div className="feedback-form">
          <TextareaAutosize
            name="message"
            value={feedback}
            onChange = {handleFeedbackChange}
            className="textarea"
            minRows={1}
            maxRows={10}
            style={{border: 'none'}}
          />
        </div>
        <div className="captcha-container" style={{paddingTop:'16px'}}>

          
        <form id="g-recaptcha">
          <div className="captcha-container" style={{ paddingTop: "16px" }}>
            <div className="g-recaptcha" data-sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}></div>
            {/* <p>This site is protected by reCaptcha and the Google <a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and <a href="https://policies.google.com/terms" target="_blank">Terms of service</a> apply</p> */}
            <br />
            
            <button  type="submit">Submit Captcha</button>

          </div>
        </form>
        </div>
      </div>
      
      <div className="frame-31">
        <div className="primary-button-feedback">
        <div className={`frame-41 ${captchaValue ? 'selected' : 'not-selected'}`}>
            {
              captchaValue ? (
                <>
                  <button onClick={handleFeedbackSend} className="send-feedback-feedback"  disabled={!captchaValue}>{getText('send')}</button>
                  {sendFeedback &&(
                    <SendFeedback feedback={feedback} rate={rate} resetForm={resetForm} language={language}/>
                  )}
                </>
              ) : (
                getText('captcha')
              )
            }
            
            
          </div>
        </div>
        {/* <p className="small-title">{getText('thank')}</p> */}
      </div>
    </div>
  )
}
