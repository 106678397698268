import React, { useState, useEffect } from "react"
import "./registration.css"
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import TermsAndConditions from "../termsConditions/TermsAndConditions";
import setHours from "date-fns/setHours";
import './datepicker.css'
import { Form, Col, Container } from "react-bootstrap"
import { Link } from "react-router-dom";



export default function RegistrationForm({onCancel, onSend, language, availableDates}) {
  
  const [name, setName] = useState('')
  const [lastName, setLastName] = useState('')
  const [doBirth, setDoBirth] = useState('')
  const [email, setEmail] = useState('')
  const [pickupDate, setPickupDate] = useState('')
  const [pickupTime, setPickupTime] = useState('')
  const [subscription, setSubscription] = useState(false)
  const [validationErrors, setValidationErrors] = useState({});
  const [renderedTime, setRenderedTime] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [conditions, setConditions] = useState(false)
  const [busyHours, setBusyHours] = useState([])
  const [receivedBusyHours, setReceivedBusyHours] = useState()
  const [captchaValue, setCaptchaValue] = useState("")
  const [walkIn, setWalkIn] = useState(false)
  const openModal = () => {
    setIsModalOpen(true)
  }
  const closeModal = () =>{
    setIsModalOpen(false)
  }
  const handleAgree = () =>{
    setConditions(true)
    closeModal()
  }
  const handleDisagree = () =>{
    setConditions(false)
    closeModal()
  }


  const getText = (key) =>{
    if (language === 'DE'){
      switch (key){
        case 'errorName':
          return 'Name*'
        case 'errorLastName':
          return 'Nachname*'
        case 'errorBirth':
          return 'Geburtsdatum*'
        case 'errorMail':
          return 'E-mail*'
        case 'errorPickDate':
          return 'Abholdatum der RFID-Karte*'
        case 'errorPickTime':
          return 'Abholzeitraum der RFID-Karte*'
        case 'walkIn':
          return 'Bitte wähle dies nur aus, wenn du vor Ort bist und mit unseren Greenbox-Mitarbeitern gesprochen hast. Während dieser Testphase sind unsere Mitarbeiter einmal pro Woche vor Ort, wenn die Ladestation verfügbar ist.'
        case 'errorWalkIn':
          return 'Bitte wähle entweder Datum und Uhrzeit oder das Kontrollkästchen'
        case 'errorConditions':
          return 'Allgemeine Geschäftsbedingungen'
        case 'title':
          return 'Registrierung als Testnutzer*in.'
        case 'titleText':
          return 'Du kannst eine neue mobile Schnellladestation zum Aufladen deines Elektroautos testen. Zahle eine Teilnahmegebühr von 17,5 Euro und lade dein E-Auto mindestens einmal pro Monat. '
        case 'revokeText':
          return (
            <div>
              Ich kann meine Einwilligung jederzeit ohne Angabe von Gründen und ohne dass mir daraus Nachteile entstehen mit Wirkung für die Zukunft gegenüber der Greenbox Mobile Energy GmbH per <a href='mailto:contact@greenbox.global' style={{color:' rgba(31, 31, 31, 1)'}}>E-Mail</a> widerrufen. Weitere Informationen zum Datenschutz finden Sie in unseren <Link
          to={{
            pathname: '/datenschutz',
            state: { language },
            search: `?language=${language}`
          }}
          rel="noopener noreferrer"
          style={{color:' rgba(31, 31, 31, 1)'}}
        >
          {getText('privacyAdd')}
        </Link>
            </div>
          )
        
        case 'privacyAdd':
          return 'Datenschutzbestimmungen.'
        case 'readTerms':
          return 'Ich habe die Allgemeinen Geschäftsbedingungen und Leistungsbeschreibung zur Testnutzung der mobilen Greenbox Ladesäule gelesen und stimme ihnen zu*'
        case 'subscribe':
          return 'Newsletter abonnieren'
        case 'cancel':
          return 'Abbrechen'
        case 'send':
          return 'Senden'
        case 'captcha':
          return 'ReCAPTCHA'
      }

    }
    switch (key){
      case 'errorName':
        return 'First Name*'
      case 'errorLastName':
        return 'Last Name*'
      case 'errorBirth':
        return 'Date of Birth*'
      case 'errorMail':
        return 'E-mail*'
      case 'errorPickDate':
        return 'Pick Up Date*'
      case 'errorPickTime':
        return 'Pick Up Time'
      case 'walkIn':
        return 'Please select this only when you are on site and have spoken to our Greenbox Staff. During this stage of testing our staff is there once a week when the charger is available.'
      case 'errorWalkIn':
        return 'Please, select either Date and Time, or Checkbox'
      case 'errorConditions':
        return 'Terms and Conditions'
      case 'title':
        return 'Registration as a test user.'
      case 'titleText':
        return ' You can test a new mobile fast charging station to charge your EV. Pay a participation fee of 17,5 euros and charge your EV at least once per month.'
      case 'revokeText':
        return  (
          <div>
            I can revoke my consent at any time without giving reasons and without suffering any disadvantages with effect for the future to Greenbox Mobile Energy GmbH by <a href='mailto:contact@greenbox.global' style={{color:' rgba(31, 31, 31, 1)'}}>Email.</a> Further information on data protection can be found in our <Link
          to={{
            pathname: '/datenschutz',
            state: { language },
            search: `?language=${language}`
          }}
          rel="noopener noreferrer"
          style={{color:' rgba(31, 31, 31, 1)'}}
        >
          {getText('privacyAdd')}
        </Link>
          </div>
        )
      // case 'revokeFinish':
      //   return 'Further information on data protection can be found in our '
      case 'privacyAdd':
        return ' Privacy Policy.'
      case 'readTerms':
        return ' I have read and agree to the Terms and Conditions and service description of the test use of the Greenbox mobile charging station*'
      case 'subscribe':
        return 'Subscribe to Newsletter'
      case 'cancel':
        return 'Cancel'
      case 'send':
        return 'Send'
      case 'captcha':
        return 'ReCAPTCHA'

    }
  }





  const cleanForm = ()=>{
    setName('')
    setLastName('')
    setDoBirth('')
    setEmail('')
    setPickupDate('')
    setPickupTime('')
    setConditions(false)
    setSubscription(false)
    onCancel(false)
  }

  const isSameDay = (dateA, dateB) =>{
    return(
      dateA.getFullYear() === dateB.getFullYear() &&
      dateA.getMonth() === dateB.getMonth() &&
      dateA.getDate() === dateB.getDate()
    )
  }



  const availableTimeSlotsObject = [
    { time: '11:00', index: 0 },
    { time: '12:00', index: 1 },
    { time: '13:00', index: 2 },
    { time: '14:00', index: 3 },
    { time: 'Walk in', index: 4 }
  ]
  const availableDatesConst = [
    new Date('2023-07-27'),
    new Date('2023-08-03'),
    new Date('2023-08-10'),
    new Date('2023-08-17'),
    new Date('2023-08-24'),
    new Date('2023-08-31')
  ]
  const availableTimes = availableTimeSlotsObject
  .filter((slot) => slot.index !== 4)
  .map((slot) => new Date().setHours(Number(slot.time.split(':')[0]), 0))

  let minTime = availableTimes[0]
  let maxTime = availableTimes[availableTimes.length - 1]



  const handleDateTimeChange = (date) => {

    setPickupDate(date)
    setBusyHours()
    if (date) {
      let busyHoursArray = []
      if (receivedBusyHours !== null){
        
        receivedBusyHours.forEach((busyDates)=>{
          if (isSameDay(new Date(busyDates.rf_id_pick_date), date)===true){
            let time = busyDates.rf_id_pick_time
            availableTimeSlotsObject.map((object)=>{
              let slot = parseInt(object.time)||'Walk In'
              if (time === 0) time = 11
              else if (time === 1) time = 12
              else if (time === 2) time = 13
              else if (time === 3) time = 14
              else if (time === 5) time = 'Walk In'
              if (slot === time) {
                if (time !== 'Walk In') busyHoursArray.push(setHours(date, time))              
              }
            })
            setBusyHours(busyHoursArray)
            
          } 
        })
      }
      const time = date.getHours()
      let checkArray =[]
      availableTimeSlotsObject.forEach((object, index)=>{
        let slot = parseInt(object.time)||'Walk In'
        checkArray.push(slot)
        if (slot === time) {
          setPickupTime(object.index)
          setRenderedTime(object.time)
          return
        } else {
          setPickupTime(null)
        }
      })
    } else {
      setPickupTime(null)
    }
  };

  const checkForm = () => {
    const errors = {}
  
    if (name === '') {
      errors.name =  getText('errorName')
    }
  
    if (lastName === '') {
      errors.lastName = getText('errorLastName')
    }

    if (doBirth === '') {
      errors.doBirth = getText('errorBirth')
    }
  
    if (email === '') {
      errors.email = getText('errorMail')
    }
  
    if (pickupDate === ''&& walkIn === false) {
      errors.pickupDate = getText('errorPickDate')
    }
  
    if (pickupTime === '' && walkIn === false) {
      errors.pickupTime = getText('errorPickTime')
    }
    if (pickupTime !== '' && walkIn === true){
      errors.walkIn = getText('errorWalkIn')
    }
  
    if (!conditions) {
      errors.conditions = getText('errorConditions')
    }
    
  
    setValidationErrors(errors)
  
    if (Object.keys(errors).length === 0 && captchaValue !== '') {
      console.log('we are inside IF')
      let formData
      let lang = language 
      if (walkIn === true){
        let pickupDateWalkIn = new Date().toISOString()
        let pickupTimeWalkIn = 4
        formData = {
          name,
          lastName,
          doBirth,
          email,
          pickupDateWalkIn,
          pickupTimeWalkIn,
          conditions,
          subscription,
          lang
        }
      } else {
        formData = {
          name,
          lastName,
          doBirth,
          email,
          pickupDate,
          pickupTime,
          conditions,
          subscription,
          lang
        }
      }
      onSend(formData)
    }
    
  }
  const convertedDates = availableDates.map((date)=>{
    const [day, month, year] = date.split('/')
    return new Date (`${year}-${month}-${day}`)
  })

  useEffect(() => {
    const scriptCaptcha = document.createElement("script")
    scriptCaptcha.src = "https://www.google.com/recaptcha/api.js"
    document.body.appendChild(scriptCaptcha)
  
    const handleSubmit = (event) => {
      event.preventDefault()
      const form = event.target
      const captchaValue = form.querySelector(".g-recaptcha-response").value
      setCaptchaValue(captchaValue)
    };
  
    const form = document.querySelector("#g-recaptcha")
    form.addEventListener("submit", handleSubmit);
  
    return () => {
      form.removeEventListener("submit", handleSubmit)
    }
  }, [])
  



  useEffect(()=>{
    const fetchStatus = async()=>{
      try{
        const url = `api/pilot/confirmed-date-time`
        const response = await axios.get(url)
        const data = response.data
        setReceivedBusyHours(data.data)
      } catch (error){
      }
    }
    fetchStatus()
  },[])

  return (
    <div className="registration-form">
      <div id="registation-header" className="registation-header">
        <p className="small-title">GME Pilot Phase 0.1 </p>
        <p className="title">{getText('title')}</p>
      </div>
      <p className="you-can-test"> {getText('titleText')}</p>
      <div id="registration-form" className="form-groups">
   
          <Container className="contatiner-div">
            <Col id="firstName" >
              <Form.Group controlId="firstName">
                <Form.Label className="name">{getText('errorName')}</Form.Label>
                <Form.Control
                  className="group-16"
                  type="text"
                  onChange={(event) => setName(event.target.value)}
                />
                {validationErrors.name && <div className="invalid-feedback">{validationErrors.name}</div>}
              </Form.Group>
            </Col>
            
      
            
  
            <Col id="lastName">
              <Form.Group controlId="lastName">
                <Form.Label className="name">{getText('errorLastName')}</Form.Label>
                <Form.Control
                  className="group-16"
                  type="text"
                  onChange={(event) => setLastName(event.target.value)}
                />
                {validationErrors.lastName && <div className="invalid-feedback">{validationErrors.lastName}</div>}
              </Form.Group>
            </Col>
            
            
            <Col id="dateBirth">
              <Form.Group controlId="formDate">
                <Form.Label className="name">{getText('errorBirth')}</Form.Label>
                <div className="group-16">
                  <DatePicker
                    selected={doBirth}
                    onChange={(date) => setDoBirth(date)}
                    dateFormat="dd/MM/yyyy"
                    showYearDropdown
                    yearDropdownItemNumber={100}
                    scrollableYearDropdown
                    className="custom-datepicker"
                  />
                </div>
                {validationErrors.doBirth && <div className="invalid-feedback">{validationErrors.doBirth}</div>}
              </Form.Group>
            </Col>
           
            <Col id="mail">
              <Form.Group controlId="email">
                <Form.Label className="name">{getText('errorMail')}</Form.Label>
                <Form.Control
                  className="group-16"
                  type="email"
                  onChange={(event) => setEmail(event.target.value)}
                />
                {validationErrors.email && <div className="invalid-feedback">{validationErrors.email}</div>}
              </Form.Group>
            </Col>
           
            <div id="dates" className="rows-and-cols">
              <Col >
                <Form.Group controlId="pickUpDay" >
                  <Form.Label className="name">{getText('errorPickDate')}</Form.Label>
                  <div className="group-17">
                    <DatePicker
                      selected={pickupDate}
                      onChange={handleDateTimeChange}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={60}
                      minTime={minTime}
                      maxTime={maxTime}
                      dateFormat="yyyy-MM-dd"
                      className="custom-datepicker"
                      placeholderText=" Select Day"
                      excludeTimes={busyHours}
                      filterDate={(date) =>{
                        return convertedDates.some((availableDate) =>
                          date.getFullYear() === availableDate.getFullYear() &&
                          date.getMonth() === availableDate.getMonth() &&
                          date.getDate() === availableDate.getDate()
                        )
                      }}    
                    />
                    {validationErrors.pickupDate && <div className="invalid-feedback">{validationErrors.pickupDate}</div>}
                  </div>
                </Form.Group>
              </Col>
              <Col >
                <Form.Group controlId="pickUpTime" >
                  <Form.Label className="name">{getText('errorPickTime')}</Form.Label>
                    <div className="group-17">
                      <input 
                        disabled
                        value={renderedTime}
                        className="custom-datepicker"
                    />
                    </div>
                </Form.Group>
              </Col>
            </div>

            <Col id="captcha">
              <Form.Group controlId="walkIn" >
                <div className="checkbox-walk a">
                  <Form.Check
                    // className="sized-checkbox"
                    type="checkbox"
                    checked = {walkIn}
                    onChange={()=>setWalkIn(!walkIn)}
                    // style={{width:'18px', height:'18px'}}
                  />
                  <label className="name">{getText('walkIn')}</label>
                </div>
              </Form.Group>
            </Col>
          
       
       

        <div className="captcha-container">
          <form id="g-recaptcha">
            <div className="captcha-container" style={{ paddingTop: "16px" }}>
              <div className="g-recaptcha" data-sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}></div>
              
              {/* <p>This site is protected by reCaptcha and the Google <a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and <a href="https://policies.google.com/terms" target="_blank">Terms of service</a> apply</p> */}

              <button type="submit">Submit Captcha</button>
              <br />
            </div>

          </form>
        </div>
        </Container>
      </div> 
      <div id="revoke"className="revoke"> 
        <p >
           {getText('revokeText')}
        </p>
      </div>

      <div className="terms-div" id="clickboxes">
        <div className="checkbox-walk">
        
          <Form.Check
            type={'checkbox'}
            checked={conditions}
            onChange={()=>setConditions(!conditions)}
            />

            <a  
              
              onClick={openModal} 
              cursor= 'pointer'
              background-color= 'transparent' 
              border= 'none' 
            >
              <p  className="you-can-test underlined">
                {getText('readTerms')}
              </p>
            </a>
          {validationErrors.conditions && <div className="invalid-feedback">{validationErrors.conditions}</div>}
        </div>
        <TermsAndConditions 
          isOpen = {isModalOpen}
          closeModal = {closeModal}
          handleAgree = {handleAgree}
          handleDisagree = {handleDisagree}
          language = {language}
        />
        <div className="checkbox-walk">
          <Form.Check
            type={"checkbox"}
            // style={{marginTop:'0px'}}
            checked = {subscription}
            onChange={()=>setSubscription(!subscription)}
          />
          <label className="you-can-test">{getText('subscribe')}</label>
        </div>
      </div>

      <div className="click-area-buttons" id="registration-buttons">
        <div className="primary-button">
          <div className={`frame-41 ${captchaValue ? 'selected' : 'not-selected'}`}>
            {
              captchaValue ? (
                <>
                  <button className="become-atest-user " onClick={checkForm} disabled={!captchaValue}>{getText('send')}</button>  
                </>
              ) : (
                <p style={{margin:0}}>{getText('captcha')}</p>
              )
            }
          </div>
        </div>
        <div className="secondary-button">
          <div className="frame-14 clip-contents">
            <a className="give-us-feedback" onClick={cleanForm} href="/" >{getText('cancel')}</a>
          </div>
        </div>
        
        
      </div>
    </div>
  )
}
