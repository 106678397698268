import "./footer.css";
import { Link } from "react-router-dom";

export default function Footer({ language }) {
  function LinkedINLogo() {
    return (
      <div>
        {" "}
        <img className="image1" src={"/Icons/3x/LinkedIn.svg"} alt="Icon 1" />{" "}
      </div>
    );
  }

  return (
    <div className="overall-footer">

      <div className="aligned">
        {language === "DE" && (
          <div >
            {" "}
            <div className="aligned email_and_linkedin_font line-break">
              {" "}
            <p> <a href="mailto:pilot@greenboxmobile.app" style={{color:'black'}}>E-Mail</a> </p>
            </div>
            <div className="flex-container aligned linksng line-break">
              {" "}
              <LinkedINLogo />
              <a 
                href="https://www.linkedin.com/company/greenboxmobile/"
                style={{color:'black'}}
              >
                Folge uns auf LinkedIn{" "}
              </a>
            </div>
            <div className="flex-container aligned links line-break">
              {" "}
              <img
                className="image1"
                src={"/Icons/3x/icon green-box@3x.svg"}
                alt="Icon 2"
              />{" "}
              Greenbox Mobile Energy, Berlin |{" "}
              <Link
                to={{
                  pathname: "/impressum",
                  state: { language },
                  search: `?language=${language}`,
                }}
                rel="noopener noreferrer"
                className="links"
              >
                Impressum
              </Link>{" "}
              |{" "}
              <Link
                to={{
                  pathname: "/datenschutz",
                  state: { language },
                  search: `?language=${language}`,
                }}
                rel="noopener noreferrer"
                className="links"
              >
                Datenschutz
              </Link>{" "}
            </div>{" "}
          </div>
        )}

        {language === "EN" && (
          <div className="aligned">
            {" "}
            <div className=" email_and_linkedin_font line-break">
              {" "}
             <p > <a href="mailto:pilot@greenboxmobile.app" style={{color:'black'}}>Email</a> </p>
            </div>
            <div className="flex-container aligned linksng line-break">
              {" "}
              <LinkedINLogo />
              
              <a 
                href="https://www.linkedin.com/company/greenboxmobile/"
                style={{color:'black'}}
              >
                Follow us on LinkedIn  
              </a>
            </div>
            <div className="flex-container aligned links line-break">
              {" "}
              <img
                className="image1"
                src={"/Icons/3x/icon green-box@3x.svg"}
                alt="Icon 2"
              />{" "}
              Greenbox Mobile Energy, Berlin |{" "}
              <Link
                to={{
                  pathname: "/impressum",
                  state: { language },
                  search: `?language=${language}`,
                }}
                rel="noopener noreferrer"
                className="links"
              >
                Site Notice
              </Link>{" "}
              |{" "}
              <Link
                to={{
                  pathname: "/datenschutz",
                  state: { language },
                  search: `?language=${language}`,
                }}
                rel="noopener noreferrer"
                className="links"
              >
                Privacy Policy
              </Link>{" "}
            </div>{" "}
          </div>
        )}
      </div>
    </div>
  );
}
