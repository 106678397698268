import { Link, Outlet, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Alert from "./components/alertComponent/Alert";
import 'bootstrap/dist/css/bootstrap.min.css';


function App() {
  const navigate = useNavigate();
  const [alertMessage, setAlertMessage] = useState("");
  const [alertClassName, setAlertClassName] = useState("d-none");
  useEffect(() => {
    document.title = "Greenbox Mobile App";
  }, []);
  return (
    <div className="app-container">
      <Helmet>
        <meta name="description" content="Applications" />
        <link rel="icon" type="image/png" href="./favicon/faviconx3.svg" sizes="16x16" />
      </Helmet>
      <Alert message={alertMessage} className={alertClassName} />
      <Outlet
        context={{
          setAlertClassName,
          setAlertMessage,
        }}
      />
    </div>
  );
}

export default App;
