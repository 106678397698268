import {
  GoogleMap,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import "./whereToFind.css";
import mapStyles from "./mapStyles.js";
import { useState, useEffect } from "react";


// const isMobileDevice = () => {
//   const userAgent = window.navigator.userAgent.toLowerCase();
//   return /mobile|android/.test(userAgent);
// };
const mapOptions = {
  styles: mapStyles,
  disableDefaultUI: true,
  zoomControl: true,
};
const center = {
  lat: 51.37072,
  lng: 9.633442,
};
const marker = {
  lat: 51.370982,
  lng: 9.633351,
};
const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;

const WhereToFind = (props) => {
  const [isInfoWindowOpen, setIsInfoWindowOpen] = useState(true)
  const [isMobile, setIsMobile] = useState(false)
  const [isMapLoaded, setMapLoaded] = useState(false)

  useEffect(() => {
    const loadScript = () => {
      const script = document.createElement('script')
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}`
      script.async = true
      script.onload = () => setMapLoaded(true)
      document.body.appendChild(script)
    };

    loadScript()

    return () => {
      const script = document.querySelector(`script[src="https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}"]`);
      if (script) {
        script.remove()
      }
    };
  }, [])
  useEffect(() => {
    const handleResize = () => {
      let isMobile = window.innerWidth <= 768
      setIsMobile(isMobile)
    }

    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  // if (!isMapLoaded) {
  //   return <div>If you would like to see the exact location, please, accept our Cookies</div>;
  // }



  const handleMarkerClick = () => {
    setIsInfoWindowOpen(true)
  };
  const handleCloseInfoWindow = () => {
    setIsInfoWindowOpen(false)
  };

  const getText = (key) => {
    if (props.language === "DE") {
      switch (key) {
        case "where":
          return "WO finde ich die GME Ladestation?";
        case "info":
          return "GME Ladestation";
        case "address":
          return "Die GME Ladestation befindet sich auf dem PkW Parkplatzbereich des 24-Autohof Lutterberg, zwischen Bundesstraße und dem 24-Autohof  Gebäude (Verkauf und Gastronomie). Wenn du auf das Gelände des 24-Autohof Lutterberg fährst, biege rechts auf den Parkplatz ab. Dort befindet sich die GME Ladestation im hinteren Bereich auf der linken Seite. ";
      }
    }
    switch (key) {
      case "where":
        return "Where to find the EV-Charger?";
      case "info":
        return "GME EV-Charger";
      case "address":
        return "The GME EV-Charger is located in the parking lot area of 24-Autohof Lutterberg, between the main road and the 24-Autohof building ( shop and restaurant). When you drive onto the premises of 24-Autohof Lutterberg, turn right into the parking lot. There you will find the GME EV-Charger in the back area on the left side.";
    }
  }
  

  const desktopMapContainerStyle = {
    width: "32vw",
    height: "32vh",
  }
  const mobileMapContainerStyle = {
    width: '82vw',
    height: '50vh',
    
  }
  const mapContainerStyle = isMobile ? mobileMapContainerStyle : desktopMapContainerStyle

  


  return (
    <div className="where-to-find">
      <p className="where-text">{getText("where")}</p>
      <div className="map-image">
          <img
            src="/Images/ChargingStation_24hsaf.jpg"
            alt="battery"
            className="image-battery"
          />
        
        <br />
        {isMapLoaded && (
          <div className="google-map">
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            zoom={16.5}
            options={mapOptions}
            center={center}
          >
            <Marker key={1} position={marker} onClick={handleMarkerClick} />
            {isInfoWindowOpen && (
              <InfoWindow
                position={marker}
                onCloseClick={handleCloseInfoWindow}
              >
                <div className="info-window">
                  <h4>{getText("info")}</h4>
                </div>
              </InfoWindow>
            )}
          </GoogleMap>
          </div>
        )}
        {!isMapLoaded && ( 
          <div>If you would like to see the exact location, please, accept our Cookies</div>
        )}
              
        
      </div>
      <div className="explanation">
        <div className="address-link">
          <img
            src="/Icons/3x/icon_location.svg"
            alt="loc"
            className="icon-location"
          />
          <p className="autohof">24-Autohof Lutterberg</p>
        </div>
        <p className="address-link">Triftstraße 11, 34355 Staufenberg</p>
        <p className="address-text">{getText("address")}</p>
      </div>
    </div>
  );
};

export default WhereToFind;
