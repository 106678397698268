import "./impressum.css"
import { Link } from "react-router-dom"
import { useLocation } from "react-router-dom"
import React, {useState} from "react"

export default function Impressum () {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const language = searchParams.get('language')
  const [sendLanguage, setSendLanguage] = useState(language)
  if (language === 'EN'){
    return(
      <div className="impressum">
        <h1>Site Notice</h1>

        <h2>Information pursuant to Sect. 5 German Telemedia Act (TMG)</h2>
        <p>Greenbox Mobile Energy GmbH<br />
        Mariendorfer Damm, 1<br />
        c/o The Drivery<br />
        12099 Berlin</p>
       
        <p>Commercial Register: HRB 246587 B<br />
        Registration court: Amtsgericht Charlottenburg</p>
   
        <p><strong>Represented by:</strong><br />
        Dr.  Henning Heppner</p>

        <h2>Contact</h2>
        <p>Phone: &#91;Telefonnummer&#93;<br />
        E-mail: contact@greenbox.global</p>

        <h2>VAT ID</h2>
        <p>Sales tax identification number according to Sect. 27 a of the Sales Tax Law:<br />
        29/316/31119</p>

        <h2>EU dispute resolution</h2>
        <p>The European Commission provides a platform for online dispute resolution (ODR): <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> Our e-mail address can be found above in the site notice.</p>

        <h2>Dispute resolution proceedings in front of a consumer arbitration board</h2>
        <p>We are not willing or obliged to participate in dispute resolution proceedings in front of a consumer arbitration board.</p>
        <div className="primary-button">
          <div className="frame-41">
            <Link to="/" className="send-feedback" style={{ textDecoration: 'none' }}>Go back</Link>
          </div>
        </div>
      </div>
    )
  } else if (language==='DE'){
    return(
      <div className="impressum">
        <h1>Impressum</h1>

        <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
        <p>Greenbox Mobile Energy GmbH<br />
        Mariendorfer Damm, 1<br />
        c/o The Drivery<br />
        12099 Berlin</p>

        <p>Handelsregister: HRB 246587 B<br />
        Registergericht: Amtsgericht Charlottenburg</p>

        <p><strong>Vertreten durch:</strong><br />
        Dr.  Henning Heppner</p>

        <h2>Kontakt</h2>
        <p>Telefon: &#91;Telefonnummer&#93;<br />
        E-Mail: contact@greenbox.global</p>

        <h2>Umsatzsteuer-ID</h2>
        <p>Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:<br />
        29/316/31119</p>

        <h2>EU-Streitschlichtung</h2>
        <p>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>

        <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
        <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
        <div className="primary-button">
        <div className="frame-41">
            <Link to={{
              pathname: '/',
              search: `?sendLanguage=${sendLanguage}`
            }}
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
            
            >Zurückgehen</Link>
            
          </div>
        </div>
      </div>
    )
  }
  
}


