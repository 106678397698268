import './termsConditions.css'
import React from 'react'
import Modal from 'react-modal'
import TermsAndConditionsNew from './TermsAndConditionsText'

const customStyles = {
  content: {
    top: '10%',
    left: '5%',
    right: '5%',
  },
  overlay:{
    backgroundColor: 'white',
    zIndex: 5
  }
}
const TermsAndConditions = ({isOpen, closeModal, handleAgree, handleDisagree, language}) =>{
  const getText = (key) =>{
    if (language === 'DE'){
      switch (key) {
        case 'disagree':
          return 'Nicht einverstanden'
        case 'agree':
          return 'Bestätigen und zurückgehen'
      }
    }
    switch (key) {
      case 'disagree':
        return 'Disagree'
      case 'agree':
        return 'Confirm and go back'
    }
  }
  
  return(
    <div>
      <Modal isOpen={isOpen} onRequestClose={closeModal} style={customStyles}>
        <div>
          <TermsAndConditionsNew language={language}/>
        </div>

        <div id='terms-button' className='cta'>
          <div className='primary-button'>
            <div className='frame-41'>
              <button className='become-atest-user' onClick={handleAgree}>{getText('agree')}</button>
            </div>
          </div>
          <div >
            <div className='frame-14'>
              <a className='give-us-feedback' onClick={handleDisagree}>{getText('disagree')}</a>
            </div>
          </div>
          
          
          
        </div>
        
      </Modal>
      
    </div>
  )
}

export default TermsAndConditions
