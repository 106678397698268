import { useEffect, useState } from "react";
import { HashLink } from "react-router-hash-link";
import axios from "axios";
import "./checkAvailability.css";

export default function CheckAvailability(props) {

  const [exactVesrion, setExactVersion] = useState(0)
  const [percentage, setPercentage] = useState(0)
  const [kwHours, setKWHours] = useState(0)
  const [batteryStatus, setBatteryStatus] = useState(null)

  useEffect(() => {
    setExactVersion(getExactVers())
  });


  const [plugs, setPlugs] = useState([
    { 
      status: "Unavailable", 
      type: "COMBO CCS2 EU", 
      watts: "100kW", 
      current: "DC" },
    {
      status: "Available",
      type: "COMBO CCS2 EU",
      watts: "100kW",
      current: "DC",
    },
  ])

  async function fetchConnectorStatus() {
    try {
      const url = "api/pilot/connector-status"
      const response = await axios
        .get(url)
        .then((resp) => {
          let receivedinfo = resp.data
          delete receivedinfo.data.chargerName
          const values = Object.values(receivedinfo.data)
          const numberOfObjects = values.length

          if (numberOfObjects === 1) {
            const receivedPlugs = [...plugs]
            receivedPlugs.forEach((plug)=>{
              plug.status = receivedinfo.data.connectorZero.status
            })
            setPlugs(receivedPlugs)
          } 
          else  {
            const receivedPlugs = [...plugs]
            receivedPlugs[0].status = values[0].status
            receivedPlugs[1].status = values[1].status
            setPlugs(receivedPlugs)
          
          }
        }
        )
        .catch()
    } catch (error) {
    }
  }

  async function getPlugsFromServer(){
    try{
      const url = "api/pilot/battery-percentage"
      const response = await axios.get(url)
      let receivedinfo = response.data
      const batteryPercentage = receivedinfo.data.batteryPercentage
      setPercentage(batteryPercentage)
      setKWHours(batteryPercentage*220/100)
      setBatteryStatus(receivedinfo.data.batteryStatus)
    }catch(error){}
  }

  
  // const ratioOfKWh = kwHours/300 * 100
  let backgroundColorCircle = ''
  let textPercentage = ''
  if (percentage<=100 && percentage>50) {
    backgroundColorCircle='#74993F'
    textPercentage = '#74993F'
  }
  else if (percentage<=50 && percentage>25) {
    backgroundColorCircle='#E5D73E'
    textPercentage='#B9AB16'
  }
  else if(percentage<=25 && percentage>0) {
    backgroundColorCircle='#FF7246'
    textPercentage='#FF7246'
  }
  const angle = (percentage/100) * 360
  const circleStyle = {
    borderRadius: '50%',
    background: `conic-gradient(${backgroundColorCircle} ${angle}deg, #1F1F1F ${angle}deg 360deg)`, 
    transform: 'rotate(180deg)'
  }


  //======this is the useEffect() for fetching plugs. As of right now, it's a const of 300kWh

  useEffect(() => {
    fetchConnectorStatus();
  }, []);
  useEffect(()=>{
    getPlugsFromServer()
  },[])
  //======once it is done, then setPlugs

  const getText = (key) => {
    if (props.language === "DE") {
      switch (key) {
        case "chargerFalse":
          return (
            <div>
              EV-Charger Thomas wartet auf sein E-Pack Edison. Dieser wird gerade
              <span style={{ color: "black" }}> im Solarpark aufgeladen.</span> 
            </div>
          )

        case "*":
          return "*Energiemenge, die verfügbar ist, bis das E-Pack (Energiespeichersystem) ausgetauscht werden muss. Erfahren Sie mehr über unseren Betrieb in unserem FAQ-Bereich."
        
        case "Waiting for the date":
          return (
            <div>
              Ladestation Thomas ist
              <span style={{ color: "black" }}> derzeit nicht verfügbar.</span> Wir arbeiten daran.
            </div>
          )

        case "nextFalse":
          return "Ladestation wieder verfügbar am:"

        case "chargerTrue":
          return (
            <div className="g1CINfontGreen">
              Die Ladestation Thomas ist
              <span style={{ color: "black" }}> derzeit in Betrieb</span>
            </div>
          )

        case "ENERGY AVAILABLE":
          return "VERFÜGBARE ENERGIE"

        case "PLUGS AVAILABLE":
          return "VERFÜGBARE LADESTECKER"

        case "Occupied":
          return "Belegt"

        case "Available":
          return "Verfügbar"

      }
    }
    switch (key) {
      case "chargerFalse":
        return (
          <div>
            EV-Charger Thomas is waiting for its E-Pack Edison. E-Pack Edison{" "}
            <span style={{ color: "black" }}>is currently recharging</span> at the solar park.
          </div>
        )

      case "*":
        return (
          <div>
            *Amount of energy available until E-pack (Energy Storage System) needs to be swapped. Learn more about our operations in our <HashLink to={'#faq'} style={{color:'#4e672a'}} >FAQ</HashLink> section.
          </div>
        ) 

      case "Waiting for the date":
        return (
          <div>
            EV&#8209;Charger Thomas is
            <span style={{ color: "black" }}> currently not available. </span><br />
            We are working on it.
          </div>
        )
      case "nextFalse":
        return "EV-Charger next available on"

      case "chargerTrue":
        return (
          <div className="g1CINfontGreen">
            EV-Charger Thomas is{" "}
            <span style={{ color: "black" }}> currently in operation.</span>
          </div>
        )

      case "ENERGY AVAILABLE":
        return "ENERGY AVAILABLE"

      case "PLUGS AVAILABLE":
        return "PLUGS AVAILABLE"

      case "Occupied":
        return "Occupied"

      case "Available":
        return "Available"

    }
  };

  function getExactVers() {
    if (props.mainCheck === true) return 1
    else if (props.status === false && props.mainCheck === false) return 2
    else if (props.status === true && props.nextAvailable === "Please, come in"&& props.mainCheck === false) {
      return 3
    }
  }

  return (
    <div>
      {exactVesrion === 1 && (
        <div className={`available-check false`}>
          <p className="ev-charger-thomas ">
            {getText("Waiting for the date")}
          </p>
        </div>
      )}

      {exactVesrion === 2 && (
        <div className={`available-check ${props.status}`}>
          <p className="ev-charger-thomas">
            {getText("chargerFalse")}
          </p>
          <div className="availability-check">
            <p className="rAvailableAtfont">
              {getText("nextFalse")}
            </p>
            <div className="group-check-available">
              <div
                disabled
                className="text-next-available-check"
              >
                {props.nextAvailable}
              </div>
            </div>
          </div>
        </div>
      )}
      {exactVesrion === 3 && (
        <div className={`available-check ${props.status}`}>
          <p className="ev-charger-thomas ">
            {getText("chargerTrue")}
          </p>
          <div className="availability-check">
            <p className="g2ENAVfont">
              {getText("ENERGY AVAILABLE")}
            </p>

            <div className="kwhCircle2" style={circleStyle}>
              {" "}
              <div className="kwhCircle" >
            
                {" "}
                <div style={circleStyle}></div>
                <div className="kwhnum" style={{ transform: 'rotate(180deg)' }}>est. 
                  <br/> {kwHours}kWh* <br />  
                  <h3 style={{color:textPercentage, fontFamily: 'IBM Plex Sans Condensed', fontSize: '30px'}}> {percentage}%</h3> 
                  {batteryStatus === 'old' &&
                    <div>
                      <h3 style={{color:textPercentage, fontFamily: 'IBM Plex Sans Condensed', fontSize: '14px'}}>NOT UP TO DATE</h3>
                    </div>
                  }
                  </div>{" "}
               
              </div>
            </div>
            <div className="g3Asteriskfont"> {getText("*")} </div>
            <div className="g4PlugsAvfont"> {getText("PLUGS AVAILABLE")} </div>
            <div className="flex-container-availability">
              {" "}
              {plugs.map((plug, index) => (
                <div key={index} className="plug">
                  {" "}
                  {plug.status !== "Available" && (
                    <div className="occupiedPlug">
                      <img src="/Icons/3x/Frame 44redVector.svg" alt="Icon 1" />{" "}
                      <div style={{fontFamily: 'IBM Plex Sans', fontWeight: '600', paddingBottom:'8px'}}> OCCUPIED</div>
                    </div>
                  )}
                  {plug.status === "Available" && (
                    <div className="availablePlug">
                      <img src="/Icons/3x/Frame 45.svg" alt="Icon 2" />{" "}
                      <div style={{fontFamily: 'IBM Plex Sans', fontWeight: '600', paddingBottom:'8px'}}>AVAILABLE</div>
                    </div>
                  )}
                  {/* <div> {plug.status}</div> */}
                  <h4 style={{paddingBottom:'0px', marginBottom:'0px'}}> {plug.type}</h4>
                  <div style={{fontFamily:'IBM Plex Sans', fontWeight:'400', paddingBottom:'8px'}}>
                    {" "}
                    {plug.watts}
                    {plug.current}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
