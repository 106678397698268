import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import './registerUser.css'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

Modal.setAppElement('#root');

export default function RegisterUser(props) {
  const [responseSuccess, setResponseSuccess] = useState(false)

  useEffect(() => {
    const register = async () => {
      const url = `api/pilot/register`
      const headers = {
        SecretToken: process.env.REACT_APP_SECRET_TOKEN
      }

      const data = {
        first_name: props.name,
        last_name: props.lastName,
        date_of_birth: props.doBirth,
        email: props.email,
        rf_id_pick_date: props.pickupDate || props.pickupDateWalkIn,
        rf_id_pick_time: props.pickupTime || props.pickupTimeWalkIn,
        rf_id_number: '',
        news_letter: props.subscription,
        lang: props.lang
      }

      try {
        const response = await axios.post(url, data, { headers });
        setResponseSuccess(true)
      } catch (error) {
      }
    }

    register()
  }, [props])
  const closeModal =()=>{
    setResponseSuccess(false)

  }

  const getText = (key) =>{
    if (props.lang === 'DE'){
      switch (key){
        case 'title':
          return 'Registrierung als Testnutzer*in.'
        case 'weSent':
          return 'Wir haben dir eine Email geschickt. Bitte überprüfe deinen Posteingang und bestätige deine Registrierung.'
        case 'close':
          return 'Fertig'
      }
    }
    switch (key){
      case 'title':
        return 'Registration as a test user.'
      case 'weSent':
        return 'We sent you an email. Please check your inbox and confirm your registration.'
      case 'close':
        return 'Okay. Close this window'
    }
  }

  return (
    <div>
      <Modal 
        isOpen={responseSuccess}
        style={customStyles}
      >
      <div className="registration-form-waiting-for-confirmation">
        <div className="group-26">
          <p className="small-title">GME Pilot Phase 0.1 </p>
          <p className="title">{getText('title')}</p>
          
        </div>
        <p className="we-sent-you-an-email-plea">
          {getText('weSent')} 
        </p>
        <div className="primary-button">
          <div className="frame-41 clip-contents">
            <button className="done" onClick={closeModal}>{getText('close')}</button>
          </div>
        </div>
      </div>
      </Modal>
    </div>
  );
}
