import React, { useEffect } from "react";
import "react-image-gallery/styles/css/image-gallery.css"; // Import the CSS for the carousel
import ImageGallery from "react-image-gallery";
// import { useLocation } from 'react-router-dom';
import { useState, useRef } from "react";
import Footer from "../footer/Footer";
import { PressHeader } from "./PressHeader";
import './press.css'



const PressPage = () => {

  const [selectedLanguage, setLanguage] = useState("EN")
  // const isMobile = window.innerWidth <=767
  // const isIpad = window.innerWidth >= 768 && window.innerWidth < 1100
  const handleLanguageChange = (selectedLanguage) => {
    setLanguage(selectedLanguage)
  }


  const [images, setImages] = useState([])
  const imageGalleryRef = useRef(null)

  const getText = (key) =>{
    if (selectedLanguage === 'DE'){
      switch (key){
        case 'header':
          return 'Digitale Pressemappen'
        case 'overallDescription':
          return 'Präsentation Pilotprojekt Greenbox Mobile Energy am 24-Autohof Lutterberg'
        case 'caseDescription2':
          return 'Presse Event am 13.10.2023.'
        case 'caseText':
          return 'Greenbox Mobile Energy präsentiert am 13. Oktober 2023 sein Pilotprojekt einer mobilen Ladestation mit Batteriespeicher am 24-Autohof Lutterberg. Für Ihre Recherche stehen hier Texte, Fotos und Daten sowie eine Präsentation zum Download bereit. Wenden Sie sich bei weiteren Fragen gerne an uns. Das Material befindet sich unten.'
        case 'material':
          return 'Material'
        case 'material1':
          return 'Presse Mitteilung Allgemein zu Greenbox Mobile Energy'
        case 'material2':
          return 'Presse Mitteilung Pilotprojekt Greenbox Mobile Energy am 24-Autohof Lutterberg'
        case 'material3':
          return 'Artikel Göttinger Tageblatt - Eichsfelder Tageblatt: „Absolutes Pilotprojekt“: Autohof bei Lutterberg betreibt Greenbox für E-Autos_15.10.2023_Stefan Kirchhoff'
        case 'material4':
          return 'Artikel Electrive.net - 24-Autohof Lutterberg ermöglicht netzunabhängiges Laden_17.10.2023_Sebastian Schaal'
        case 'material5':
          return 'Präsentation Greenbox Mobile Energy'
        case 'logo':
          return 'LOGO Download'
        case 'pressePhotos':
          return 'Presse Fotos'
        case 'pressePhotosDescr':
          return 'In unserem Pressefotocenter finden Sie schnell und einfach druckfähige Bilder für Ihre Berichterstattung über Greenbox Mobile Energy. Bitte beachten Sie, dass Sie die untenstehenden Bilder nur in redaktionellem Zusammenhang mit Greenbox Mobile Energy verwenden dürfen, wobei die folgenden Informationen angezeigt werden müssen Copyright: Elvina Kulinicenko / Greenbox Mobile Energy. Land: Deutschland.'
      }
    }
      switch (key){
        case 'header':
          return 'Digital Press Kits'
        case 'overallDescription':
          return 'Presentation pilot project Greenbox Mobile Energy at the 24-Autohof Lutterberg'
        case 'caseDescription2':
          return 'Press Event on 13.10.2023.'
        case 'caseText':
          return 'Greenbox Mobile Energy presents its pilot project of a mobile charging station with battery storage at 24-Autohof Lutterberg on October 13, 2023. For your research, texts, photos and data as well as a presentation are available for download here. Please feel free to contact us if you have any further questions. The material can be found below.'
        case 'material':
          return 'Material'
        case 'material1':
          return 'General press release about Greenbox Mobile Energy'
        case 'material2':
          return 'Press release pilot project Greenbox Mobile Energy at 24-Autohof Lutterberg'
        case 'material3':
          return 'Artikel Göttinger Tageblatt - Eichsfelder Tageblatt: „Absolutes Pilotprojekt“: Autohof bei Lutterberg betreibt Greenbox für E-Autos_15.10.2023_Stefan Kirchhoff'
        case 'material4':
          return 'Artikel Electrive.net - 24-Autohof Lutterberg ermöglicht netzunabhängiges Laden_17.10.2023_Sebastian Schaal'
        case 'material5':
          return 'Presentation Greenbox Mobile Energy'
        case 'material6':
          return 'Artikel HNA_Staufenberg/ A7-Raststätte Lutterberg weiht Flüssigerdgas-Zapfsäule ein_19.10.23_Celine Kühn'
        case 'logo':
          return 'LOGO Download'
        case 'pressePhotos':
          return 'Press Photos'
        case 'pressePhotosDescr':
          return 'In our press photo center you can quickly and easily find printable images for your coverage of Greenbox Mobile Energy. Please note that you can only use the images below in editorial context with Greenbox Mobile Energy, displaying the following information Copyright: Elvina Kulinicenko / Greenbox Mobile Energy. Country: Germany.'
      }
  }

  const onClickHandler = () => {
    imageGalleryRef.current.fullScreen();
  };
  
  useEffect(()=>{
    const images = [
      { original: "https://devlp.greenboxmobile.app/pilot0.1/images/Greenbox_Staex_Lutterberg_13.10.2023-66.jpg", 
        thumbnail: "https://devlp.greenboxmobile.app/pilot0.1/images/resized/Greenbox_Staex_Lutterberg_13.10.2023-66_resized.jpg", 
        description: 'Copyright: Elvina Kulinicenko / Greenbox Mobile Energy'},
      { original: "https://devlp.greenboxmobile.app/pilot0.1/images/Greenbox_Staex_Lutterberg_13.10.2023-70.jpg", 
        thumbnail: "https://devlp.greenboxmobile.app/pilot0.1/images/resized/Greenbox_Staex_Lutterberg_13.10.2023-70_resized.jpg", 
        description: 'Copyright: Elvina Kulinicenko / Greenbox Mobile Energy'},
      { original: "https://devlp.greenboxmobile.app/pilot0.1/images/Greenbox_Staex_Lutterberg_13.10.2023-49.jpg", 
        thumbnail: "https://devlp.greenboxmobile.app/pilot0.1/images/resized/Greenbox_Staex_Lutterberg_13.10.2023-49_resized.jpg", 
        description: 'Copyright: Elvina Kulinicenko / Greenbox Mobile Energy'},
      { original: "https://devlp.greenboxmobile.app/pilot0.1/images/2-Greenbox_Staex_Lutterberg_13.10.2023.jpg", 
        thumbnail: "https://devlp.greenboxmobile.app/pilot0.1/images/resized/Greenbox_Staex_Lutterberg_13.10.2023-13_resized.jpg", 
        description: 'Copyright: Elvina Kulinicenko / Greenbox Mobile Energy'},
      { original: "https://devlp.greenboxmobile.app/pilot0.1/images/1-Greenbox_Staex_Lutterberg_13.10.2023.jpg", 
        thumbnail: "https://devlp.greenboxmobile.app/pilot0.1/images/resized/Greenbox_Staex_Lutterberg_13.10.2023-11_resized.jpg", 
        description: 'Copyright: Elvina Kulinicenko / Greenbox Mobile Energy'},
      { original: "https://devlp.greenboxmobile.app/pilot0.1/images/Greenbox_Staex_Lutterberg_13.10.2023-8.jpg", 
        thumbnail: "https://devlp.greenboxmobile.app/pilot0.1/images/resized/Greenbox_Staex_Lutterberg_13.10.2023-8_resized.jpg", 
        description: 'Copyright: Elvina Kulinicenko / Greenbox Mobile Energy'},
      { original: "https://devlp.greenboxmobile.app/pilot0.1/images/Greenbox_Staex_Lutterberg_13.10.2023-3.jpg", 
        thumbnail: "https://devlp.greenboxmobile.app/pilot0.1/images/resized/Greenbox_Staex_Lutterberg_13.10.2023-3_resized.jpg", 
        description: 'Copyright: Elvina Kulinicenko / Greenbox Mobile Energy'},
      { original: "https://devlp.greenboxmobile.app/pilot0.1/images/Greenbox_Staex_Lutterberg_13.10.2023-6.jpg", 
        thumbnail: "https://devlp.greenboxmobile.app/pilot0.1/images/resized/Greenbox_Staex_Lutterberg_13.10.2023-6_resized.jpg", 
        description: 'Copyright: Elvina Kulinicenko / Greenbox Mobile Energy'},
      { original: "https://devlp.greenboxmobile.app/pilot0.1/images/Greenbox_Staex_Lutterberg_13.10.2023-73.jpg", 
        thumbnail: "https://devlp.greenboxmobile.app/pilot0.1/images/resized/Greenbox_Staex_Lutterberg_13.10.2023-73_resized.jpg", 
        description: 'Copyright: Elvina Kulinicenko / Greenbox Mobile Energy'},
      { original: "https://devlp.greenboxmobile.app/pilot0.1/images/Greenbox_Staex_Lutterberg_13.10.2023-76.jpg", 
        thumbnail: "https://devlp.greenboxmobile.app/pilot0.1/images/resized/Greenbox_Staex_Lutterberg_13.10.2023-76_resized.jpg", 
        description: 'Copyright: Elvina Kulinicenko / Greenbox Mobile Energy'},
      { original: "https://devlp.greenboxmobile.app/pilot0.1/images/Greenbox_Staex_Lutterberg_13.10.2023-77.jpg", 
        thumbnail: "https://devlp.greenboxmobile.app/pilot0.1/images/resized/Greenbox_Staex_Lutterberg_13.10.2023-77_resized.jpg", 
        description: 'Copyright: Elvina Kulinicenko / Greenbox Mobile Energy'},
      { original: "https://devlp.greenboxmobile.app/pilot0.1/images/Greenbox_Staex_Lutterberg_13.10.2023-18.jpg", 
        thumbnail: "https://devlp.greenboxmobile.app/pilot0.1/images/resized/Greenbox_Staex_Lutterberg_13.10.2023-18_resized.jpg", 
        description: 'Copyright: Elvina Kulinicenko / Greenbox Mobile Energy'},
    ];
    setImages(images)
  },[])

  return (
    <div className="main-container-desktop">
      <div >
        <PressHeader onLanguageChange={handleLanguageChange} language={selectedLanguage} />
      </div>
      <div className="pressStyle" >
        {console.log(selectedLanguage)}
        <h1 style={{color:'rgba(116, 153, 63, 1)', fontWeight:'bold'}}>{getText('header')}</h1>
        <h1 >{getText('overallDescription')}</h1>
        <h2 style={{color:'rgba(116, 153, 63, 1)', fontWeight:'bold'}}>{getText('caseDescription2')}</h2>
        <h3 style={{color:'black', lineHeight:'150%'}}>{getText('caseText')}</h3>
        <h2 style={{color:'rgba(116, 153, 63, 1)', fontWeight:'bold'}}>{getText('material')}</h2>

        <ul>
          <li>
            <h3 style={{color:'black'}}>{getText('material1')}</h3>
            <a style={{color:'grey'}} className="textBlock" href="https://devlp.greenboxmobile.app/pilot0.1/pdfs/EN/2023-10-12 General press release about Greenbox Mobile Energy.pdf" download> Download PDF (ENG)</a>
            <br />
            <a style={{color:'grey'}} className="textBlock" href="https://devlp.greenboxmobile.app/pilot0.1/pdfs/DE/2023-10-12 Pressemitteilung Allgemein Greenbox Mobile Energy.pdf" download> Download PDF (DE)</a>
            <br />
            <br />
          </li>
          <li>
            <h3 style={{color:'black'}}>{getText('material2')}</h3>
            <a style={{color:'grey'}} className="textBlock" href="https://devlp.greenboxmobile.app/pilot0.1/pdfs/EN/2023-12-10 Press release pilot project Greenbox Mobile Energy at 24-Autohof Lutterberg.pdf" download>Download PDF (ENG)</a>
            <br />
            <a style={{color:'grey'}} className="textBlock" href="https://devlp.greenboxmobile.app/pilot0.1/pdfs/DE/2023-10-12 Presse Mitteilung Pilotprojekt Greenbox Mobile Energy am 24-Autohof Lutterberg.pdf" download>Download PDF (DE)</a>
            <br />
            <br />
          </li>
          <li>
            <h3 style={{color:'black'}}>{getText('material5')}</h3>
            <a style={{color:'grey'}} className="textBlock" href="https://devlp.greenboxmobile.app/pilot0.1/pdfs/EN/23_10_11_Greenbox Mobile Energy_Presentation.pdf" download>Download PDF</a>
            <br />
            <br />
          </li>
          <li>
            <h3 style={{color:'black'}}>{getText('material3')}</h3>
            <a style={{color:'grey'}} className="textBlock" href="https://devlp.greenboxmobile.app/pilot0.1/pdfs/EN/Artikel Göttinger Tageblatt – Eichsfelder Tageblatt_Absolutes Pilotprojekt- Autohof bei Lutterberg betreibt Greenbox für E-Autos_15-10-2023 _Stefan Kirchhoff.pdf" download>Download PDF</a>
            <br />
            <br />
          </li>
          <li>
            <h3 style={{color:'black'}}>{getText('material4')}</h3>
            <a style={{color:'grey'}} className="textBlock" href="https://devlp.greenboxmobile.app/pilot0.1/pdfs/EN/Artikel Electrive.net - 24-Autohof Lutterberg ermöglicht netzunabhängiges Laden_17.10.2023_Sebastian Schaal.pdf" download>Download PDF</a>
            <br />
            <br />
          </li>
          <li>
            <h3 style={{color:'black'}}>{getText('material6')}</h3>
            <a style={{color:'grey'}} className="textBlock" href="https://devlp.greenboxmobile.app/pilot0.1/pdfs/EN/Artikel HNA_Staufenberg_ A7-Raststätte Lutterberg weiht Flüssigerdgas-Zapfsäule ein_Celine Kühn_19.10.23.pdf" download>Download PDF</a>
            <br />
            <br />
          </li>
          
          <li>
            <h3 style={{color:'black'}}>{getText('logo')}</h3>
            <a style={{color:'grey'}} className="textBlock" href="/Icons/3x/LogoRGB-GBME.svg" download>Download Logo</a>
          </li>
          
          
        </ul>
        <h2 style={{color:'rgba(116, 153, 63, 1)', fontWeight:'bold'}}>{getText('pressePhotos')}</h2>
        <h3 style={{color:'black' , lineHeight:'150%'}}>{getText('pressePhotosDescr')}</h3>

        
        <div style={{paddingLeft:'20%', paddingRight:'20%', width:'90vw'}}>
          <ImageGallery 
            items={images}
            showThumbnails={true}
            showFullscreenButton={true}
            showBullets={true}
            ref={imageGalleryRef}
            showNav = {true}
            onClick={onClickHandler}
            autoPlay = {true}
            slideDuration = {5000}
          />
        </div>
        
      </div>
      <div className="link-footer-desktop" id='footer'>
        {" "}
        <Footer language={selectedLanguage} />{" "}
      </div>
    </div>
  )
}

export default PressPage;
