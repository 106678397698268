import React, { useRef, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./desktopApp.css";
import { HashLink } from "react-router-hash-link";
import axios from "axios";
import { Header } from "./header/Header";
import StatusBar from "./statusBar/StatusBar";
import Hero from "./hero/Hero";
import SectionFeedback from "./feedback/FeedbackPage";
import CheckAvailability from "./availability/CheckAvailability";
import How from "./HowDoesItWork/How";
import FAQ from "./faq/FAQ";
import WhereToFind from "./location/WhereToFind";
import Footer from "./footer/Footer";


const DesktopApp = () => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const receivedLanguage = searchParams.get("sendLanguage")
  const [language, setLanguage] = useState(receivedLanguage || "EN")
  const [mainCheck, setMainCheck] = useState(null)
  const [availableDates, setAvailableDates] = useState([])
  const [availableStatus, setAvailableStatus] = useState(false)
  const [chargerAvailable, setChargerAvailable] = useState("")

  const scrollToTop = () =>{
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  const handleLanguageChange = (selectedLanguage) => {
    setLanguage(selectedLanguage)
  };
  const [registrationProp, setRegistrationProp] = useState(false)
  const handleLinkToRegister = (value) =>{
    setRegistrationProp(value)
  }

  const scrollToFeedbackSection = () => {
    const feedbackSection = document.getElementById("feedback")
    if (feedbackSection) {
      feedbackSection.scrollIntoView({ behavior: "smooth" })
    }
  };



  const statusRef = useRef(null)
  const scrollToStatus = () => {
    if (statusRef.current) {
      statusRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };


  async function mainWorkingCheck(){
    try {
      const url = 'api/pilot/charging-status'
      const response = await axios
      .get(url)
        .then((resp) => {
          const receivedinfo = resp.data
          setMainCheck(receivedinfo.status)
        })
        .catch();
    } catch (error) {

    }
  }




  async function fetchStatus() {
    let availableDatesArray = []
    try {
      const url = `api/pilot/battery-next-available`
      const response = await axios.get(url)
      const data = response.data
      data.data.every((dateValidation)=>{
        let availableDate = new Date(dateValidation.date_time)
        let formattedAvailableDate = availableDate.toLocaleString("en-GB", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit"
        })
        availableDatesArray.push(formattedAvailableDate)
        if (dateValidation.validity === true){
          let nextAvailableDate = new Date(dateValidation.date_time)
          const formattedDate = nextAvailableDate.toLocaleString("en-GB", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
          })
          setChargerAvailable(formattedDate)
          setAvailableStatus(false)
          return false
        } else {
          setAvailableStatus(true)
          setChargerAvailable('Please, come in')
          return true
        }

      })
      setAvailableDates(availableDatesArray)

    } catch (error) {
    }
  }

 

  useEffect(() => {
    fetchStatus();
  }, [availableStatus]);

  useEffect(()=>{
    mainWorkingCheck()
  }, [mainCheck])


  useEffect(() => {
    if (location.hash === "#status") {
      scrollToStatus()
    } 
  }, [location])



  return (
    <div className="main-container-desktop">
      <HashLink smooth to="#top" onClick={scrollToTop} className="scroll-button">
        {language === 'EN' && ( 'Scroll to top')}
        {language === 'DE' && ('Nach oben scrollen')}
       
      </HashLink>
      <div className="header-desktop" id="headerSection">
        <Header onLanguageChange={handleLanguageChange} language={language} />
      </div>
      <div className="status-bar-desktop" id="statusBarSection">
        <StatusBar status={availableStatus} language={language} mainCheck={mainCheck}/>
      </div>
      <div className="hero-desktop" id="hero">
        <Hero
          scrollToFeedbackSection={scrollToFeedbackSection}
          language={language}
          registrationProp={registrationProp}
          availableDates = {availableDates}
        />
      </div>
      <div className="how" id="how">
        <How language={language} onClickToRegistration={handleLinkToRegister}/>
      </div>
      <div className="availability-desktop" id="status" ref={statusRef}>
        <CheckAvailability
          language={language}
          status={availableStatus}
          nextAvailable={chargerAvailable}
          mainCheck={mainCheck}
        />
      </div>

      <div className="where-desktop" id="location">
        <WhereToFind language={language} />
      </div>
      <div className="feedback-section-desktop" id="feedback">
        <SectionFeedback language={language} />
      </div>
      
      <div className="faq-desktop" id="faq">
        <FAQ language={language} onClickToRegistration={handleLinkToRegister} />
      </div>

      <div className="link-footer-desktop" id='footer'>
        {" "}
        <Footer language={language} />{" "}
      </div>
    </div>
  );
};

export default DesktopApp;
