/* eslint-disable react/jsx-pascal-case */
import "./faq.css";
import { useState } from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { HashLink } from "react-router-hash-link";

import { Link } from 'react-router-dom'
/* eslint-disable default-case */
export default function FAQ({ language, onClickToRegistration }) {
  // const [language, setlanguage] = useState(props.language);
  const [currentQuestion, setcurrentQuestion] = useState("");
  const handleClick = () =>{
    const registrationProp=true
    onClickToRegistration(registrationProp)
    console.log(registrationProp)
  }

  const [faqs, setfaqs] = useState({
    language: {
      EN: {
        Subcategories: [
          {
            Title: "GME Concept",
            src: "/Icons/3x/fi_box.svg",
            Questions: [
              {
                isClicked: false,
                question: "What is GME? How does the GME EV-Charger work?",
                addendum: "",
                answers: [
                  {
                    symbol: "",
                    answer:
                      "GME, which stands for Greenbox Mobile Energy, is revolutionizing the way we power electric vehicles (EVs). Our goal is to provide customers with 100% clean and sustainable energy exactly where they need it. (Please note that GME is working towards achieving its goal of operating off the grid, but this capability is not completely available yet). Let's take a look at how it works, simplified into three easy steps:",
                  },
                  {
                    symbol: "",
                    answer:
                    "Charge Up: Our mobile energy pack (E-Pack) is conveniently charged directly from nearby renewable energy sources, located close to the point-of-sale area.",                  },
                  {
                    symbol: "",
                    answer:
                      "On the Go: Once the E-Pack is fully charged, it is transported to a designated point of sale, using a reliable truck (LKW). Here, it is seamlessly connected to an EV charger.",
                  },
                  {
                    symbol: "",
                    answer:
                      "Ready to Roll: Voila! The EV charger is now ready to power up electric vehicles. As the E-Pack starts to deplete, our advanced operation software notifies us, and we promptly dispatch a replacement E-Pack for recharging at the solar- or windpark.",
                  },
                ],
              },
              {
                isClicked: false,
                question: "Why is the GME EV-Charger mobile and offgrid?",
                addendum: "",
                answers: [
                  {
                    symbol: "",
                    answer:
                      "The GME EV-Charger is designed to be mobile and operate offgrid, providing several advantages to both GME and its customers. However, please note that GME's EV-Chargers currently rely on grid connections for operation.",
                     answer1:<div> <br />
                      1. Grid Independence: By operating offgrid, GME aims to be independent of the traditional power grid. This approach helps reduce the load on the grid and contributes to a more sustainable energy system. <br />
                      2. Local Sourcing: GME's offgrid method allows them to provide customers with 100% locally sourced green power. By utilizing renewable energy sources located near the point-of-sale, GME ensures that the energy used to charge EVs is clean and sustainable. <br />
                      3. Flexibility and Implementation: The mobile nature of GME's EV-Chargers enables them to serve customers in areas where establishing grid infrastructure is challenging or not feasible. Additionally, the mobility factor allows for fast and flexible implementation of charging stations, catering to evolving demands and needs. <br />
                      <br />
                      Please keep in mind that while GME aspires to operate off the grid in the near future, the current capabilities of their EV-Chargers may require a grid connection for operation. 
                     </div> 
                  },
                ],
              },
              {
                isClicked: false,
                question: "Why is our charger not available all the time?",
                addendum: "",
                answers: [
                  {
                    symbol: "",
                    answer:
                      "Currently, we are in the testing phase of the project (Pilot phase 0.1), where it is crucial to carefully examine each step. We have opted to proceed slowly and confidently. Initially, we will be ready to provide the E-Pack (Battery) once a week with fully charged power, gradually increasing this frequency over time. If the E-Pack is not at the EV Charger, it means it is being charged at the solar park and will return as soon as possible.",
                  },
                ],
              },
            ],
          },

          {
            Title: "Test phase: GME Pilot Phase 0.1",
            src: "/Icons/3x/icon_user.svg",

            Questions: [
              {
                isClicked: false,
                question: "Why should I participate as a test user?",
                addendum: "",
                answers: [
                  {
                    symbol: "",
                    answer:
                      "By becoming a test user, you'll have access to several exciting benefits, including:",
                  },
                  {
                    symbol: "-",
                    answer:
                      "An incredibly attractive offer to fast charge your car at the 24-Autohof Lutterberg.",
                  },
                  
                  {
                    symbol: "-",
                    answer:
                      "The opportunity to be part of the initial testing phase for a new, innovative charging solution.",
                  },
                  {
                    symbol: "-",
                    answer:
                      "The chance to help pave the way towards emission-free mobility.",
                  },
                  {
                    symbol: "-",
                    answer:
                      "Receive benefits as part of the GME Charging community.",
                  },
                  {
                    symbol: "",
                    answer:
                      "Don't miss out on this unique opportunity to play a vital role in shaping the future of sustainable mobility. Sign up today to become  ",
                    link: <HashLink to={'/#heroSection'} onClick={handleClick} style={{color:'#4e672a'}}>a test user!</HashLink>,
                  },
                ],
              },
              {
                isClicked: false,
                question:
                  "How can I become a test user and pay the participation fee?",
                addendum:
                  "**GME has to limit the number of test users since GME can offer only a limited amount of energy available per week. ***The price is net. ",

                answers: [
                  {
                    symbol: "1.",
                    answer:
                      "Register ",
                    link: <HashLink to={'/#heroSection'} onClick={handleClick} style={{color:'#4e672a'}}>online</HashLink>,
                    answer1: ' and choose a pickup date and time.**'
                  },
                  {
                    symbol: "2.",
                    answer:
                      "Drive your EV to 24-Autohof Lutterberg on the chosen pickup date.",
                  },
                  {
                    symbol: "3.",
                    answer:
                      "On the pickup date, pay the participation fee of 17,50 Euros to get your GME RFID card in return. You can pay inside the 24-Autohof Lutterberg service station. Our GME staff is also on site on the operation dates of the EV-charger to assist you in this phase. You will find them next to the EV charger located ",
                    link: <HashLink to={'/#location'} style={{color:'#4e672a'}} >here.</HashLink>,
                  },
                  {
                    symbol: "4.",
                    answer:
                      "You can use your GME RFID card instantly to charge your car if the GME EV charger is available. Check availability ",
                    link: <HashLink to={'/#status'} style={{color:'#4e672a'}} >here.</HashLink>,
                    answer1: ' Your card will be valid for 30 days to charge your car at the GME EV charger.'
                  },
                  {
                    symbol: "5.",
                    answer:
                      "To prolong your time as a test user, you will have to top up your RFID card again with 17,50 Euros so that you can continue to charge your car for another 30 days.",
                  },
                  {
                    symbol: "",
                    answer:
                    (
                      <div>
                      The overall test phase is estimated to be 3 months long from July to September 2023. We welcome you to be a test user for the whole 3 months.<br /><br />
                      **GME has to limit the number of test users, since GME can offer only a limited amount of energy available per week.

                      </div>
                    )
                  },
                ],
              },
              {
                isClicked: false,
                question: "What will I do as a test user?",
                addendum:
                  "* The EV-Charger will be available at least once a week during the test phase, with an energy amount available of 300 kWh. This energy amount will be distributed on a first-come first-serve basis. You can consult here (LINK) to see when the charger is available (next).",

                answers: [
                  {
                    symbol: "-",
                    answer:
                      "Charge your car at the GME EV-Charger at 24-Autohof Lutterberg, a service station on the highway A7 north of Kassel",
                  },
                  {
                    symbol: "-",
                    answer: "Option to fill out a survey for customer feedback",
                  },
                  {
                    symbol: "-",
                    answer:
                      "Pay a participation fee of 17,50 Euros a month, charge your car as often as you like*",
                  },
                  {
                    symbol: "-",
                    answer:
                      "Through your role as a test user, you will help to ensure that the GME mobile energy solution works smoothly and will be available to you through standard Mobility Services in the near future.",
                  },
                  {
                    symbol: "-",
                    answer: "Shape the future of EV-Charging solutions",
                  },
                  {
                    symbol: "",
                    answer: 
                      (
                        <div>
                          * The EV-Charger will be available at least once a week during the test phase, with an energy amount available of 300 kWh. This energy amount will be distributed on a first-come first-serve basis. You can consult <HashLink to={'/#status'} style={{color:'#4e672a'}} >here</HashLink> to see when the charger is available (next).
                        </div>
                      )
                  },
                ],
              },
              {
                isClicked: false,
                question:
                  "When will the test phase start and end? What is the earliest possible registration and charging date?",
                addendum: "",
                answers: [
                  {
                    symbol: "-",
                    answer: "Test phase estimated start: July 27th 2023",
                  },
                  {
                    symbol: "-",
                    answer: "Test phase estimated end: September 30th 2023",
                  },
                  {
                    symbol: "-",
                    answer:
                      "Earliest registration: As soon as this registration is active you can",
                    link: <HashLink to={'/#heroSection'} onClick={handleClick} style={{color:'#4e672a'}}>register</HashLink>,
                    answer1: ' as a test user. Once we have reached full capacity we will (temporarily) deactivate the registration. '
                  },
                  {
                    symbol: "-",
                    answer:
                      "Earliest day possible to charge your EV: When the test phase starts on July 27th we will have the first 300 kWh available at 24-Autohof Lutterberg, for later availabilities keep checking ",
                    link: <HashLink to={'/#status'} style={{color:'#4e672a'}} >here.</HashLink>,
                  },
                ],
              },
            ],
          },
          {
            Title: "How to use the charger/ Where to find support",
            src: "/Icons/3x/Vectorcharger.svg",

            Questions: [
              {
                isClicked: false,
                question:
                  "How and when can I charge my car at the GME EV-Charger at 24-Autohof Lutterberg?",
                addendum: "",
                answers: [
                  {
                    symbol: "-",
                    answer:
                      "You need to ",
                    link: <HashLink to={'/#heroSection'} onClick={handleClick} style={{color:'#4e672a'}}>register</HashLink>,
                    answer1: ' as a test user to be able to use the GME EV-Charger.'
                  },
                  {
                    symbol: "-",
                    answer:
                      "If you are one of the GME test users and you have paid your 17,50 Euros participation fee for the 30 days, you can charge your EV as often as you like and the EV-charger is ",
                    link: <HashLink to={'/#status'} style={{color:'#4e672a'}} >available.</HashLink>,
                  },
                ],
              },
              {
                isClicked: false,
                question:
                  "How can I extend my test user period beyond the first 30 days?",
                addendum: "",
                answers: [
                  {
                    symbol: "",
                    answer:
                      "If you are already registered as a test user and the first 30 days of your test period are over, to continue the test phase with GME you should top up your RFID card with another 17,50 Euros at the 24-Autohof Lutterberg service station, when the GME EV- Charger is ",
                    link: <HashLink to={'/#status'} style={{color:'#4e672a'}} >available.</HashLink>,
                    answer1: ' To complete the reactivation of your RFID-card show it and your receipt to the GME staff on-site. Your RFID card will then be active for another 30 days.'
                  },
                ],
              },
              {
                isClicked: false,
                question:
                  "How can I check if the charger is available and has enough energy?",
                addendum: "",
                answers: [
                  {
                    symbol: "",
                    answer:
                      "You can check the availability of the EV-Charger ",
                    link: <HashLink to={'/#status'} style={{color:'#4e672a'}} >here</HashLink>,
                    answer1: ' and how many kWh are left for charging.'
                  },
                ],
              },
              {
                isClicked: false,
                question:
                  "I bought a card but didn't have a chance to use it in the current month. Can I use the card in the next month?",
                addendum: "",
                answers: [
                  {
                    symbol: "",
                    answer:
                      "You can use the card for a period of 30 days. Then it will be deactivated automatically.  To renew your test period, you will need to pay the participation fee again at 24-Authof Lutterberg service station and complete the reactivation of your RFID card with the GME staff on-site.",
                  },
                ],
              },
              {
                isClicked: false,
                question:
                  "Where do I find support if the charger is not working?",
                addendum: "",
                answers: [
                  {
                    symbol: "",
                    answer:
                      "Please get in touch with our GME Staff onsite to assist you. You might also be there when the charger is currently not available, because the E-Pack is being recharged. Please consult ",
                    link: <HashLink to={'/#status'} style={{color:'#4e672a'}} >here</HashLink>,
                    answer1: ' to check when the charger is available next.'
                  },
                ],
              },
            ],
          },
          {
            Title: "GME Future / Outlook ",
            src: "/Icons/3x/Vectorbulb.svg",

            Questions: [
              {
                isClicked: false,
                question: "What happens next?",
                addendum: "",
                answers: [
                  {
                    symbol: "",
                    answer:
                      "Following the completion of the test phase, our goal at 24-Autohof Lutterberg is to consistently offer our customers green energy, allowing them to continue their charging process independently, just as they normally would. Once the test phase proves successful, we will promptly notify you about our upcoming locations. Stay informed by following us on ",
                    link: (
                    <>
                       <a href="https://www.linkedin.com/company/greenboxmobile/" style={{color:'#4e672a'}} target="_blank">LinkedIn</a>
                    </>
                    ),
                    answer1: ' and subscribing to our newsletter.'
                  },
                ],
              },
            ],
          },
        ],
      },
      DE: {
        Subcategories: [
          {
            Title: "GME-Konzept ",
            src: "/Icons/3x/icon green-box@3x.svg",

            Questions: [
              {
                isClicked: false,
                question:
                  "Was ist GME? Wie funktioniert die GME EV-Ladestation?",
                addendum: "",
                answers: [
                  {
                    symbol: "",
                    answer:
                      "GME steht für Greenbox Mobile Energy und revolutioniert die Art und Weise, wie wir Elektrofahrzeuge (EVs) mit Strom versorgen. Unser Ziel ist es, unsere Kunden mit 100% umweltfreundlicher und erneuerbarer Energie zu versorgen, genau dort, wo sie sie brauchen. (Bitte beachte, dass GME daran arbeitet, sein Ziel zu erreichen, netzunabhängig zu arbeiten, diese Funktion jedoch noch nicht vollständig verfügbar ist). Schauen wir uns einmal an, wie das funktioniert, vereinfacht in drei Schritten:",
                  },
                  {
                    symbol: "",
                    answer:
                      "Aufladen: Unser mobiles Energiepaket (E-Pack) wird bequem direkt an einer nahe gelegenen erneuerbaren Energiequelle in der Nähe der Verkaufsstelle aufgeladen.  ",
                  },
                  {
                    symbol: "",
                    answer:
                      "Unterwegs: Sobald das E-Pack vollständig aufgeladen ist, wird es mit einem zuverlässigen Lkw zu einer bestimmten Verkaufsstelle transportiert. Dort wird es nahtlos an eine Ladestation für Elektrofahrzeuge angeschlossen.",
                  },
                  {
                    symbol: "",
                    answer:
                      "Bereit zum Losfahren: Voilà! Die Ladestation ist nun bereit, Elektrofahrzeuge mit Strom zu versorgen. Wenn der E-Pack zur Neige geht, benachrichtigt uns unsere innovative Betriebssoftware, und wir schicken umgehend einen Ersatz-E-Pack zum Aufladen in den Solar- oder Windpark.",
                  },
                ],
              },
              {
                isClicked: false,
                question:
                  "Warum ist der GME EV-Charger mobil und netzunabhängig?",
                addendum: "",
                answers: [
                  {
                    symbol: "",
                    answer:
                      "Der GME EV-Charger ist für den mobilen und netzunabhängigen Betrieb konzipiert und bietet sowohl GME als auch seinen Kunden mehrere Vorteile. Bitte beachte jedoch, dass die Ladestationen von GME derzeit noch für den Betrieb auf Netzanschlüsse angewiesen sind.",
                    answer1: <div> <br />
                      1. Netzunabhängigkeit: Durch den netzunabhängigen Betrieb strebt GME danach, unabhängig vom traditionellen Stromnetz zu sein. Dieser Ansatz trägt dazu bei, die Belastung des Netzes zu reduzieren und trägt zu einem nachhaltigeren Energiesystem bei. <br />
                      2. Lokale Beschaffung: Die netzunabhängige Methode von GME ermöglicht es ihnen, Kunden mit 100 % lokal bezogenem Ökostrom zu versorgen. Durch die Nutzung erneuerbarer Energiequellen in der Nähe der Verkaufsstelle stellt GME sicher, dass die zum Laden von Elektrofahrzeugen verwendete Energie sauber und nachhaltig ist. <br />
                      3. Flexibilität und Implementierung: Der mobile Charakter der EV-Ladestationen von GME ermöglicht es, Kunden in Bereichen zu bedienen, in denen der Aufbau einer Netzinfrastruktur schwierig oder nicht machbar ist. Darüber hinaus ermöglicht der Mobilitätsfaktor eine schnelle und flexible Implementierung von Ladestationen, die auf sich ändernde Anforderungen und Bedürfnisse eingehen. <br />
                      <br />
                      Bitte bedenke, dass GME zwar in naher Zukunft einen netzunabhängigen Betrieb anstrebt, die aktuellen Kapazitäten ihrer EV-Ladestationen jedoch möglicherweise einen Netzanschluss für den Betrieb erfordern. 
                    </div>
                  },
                ],
              },
              {
                isClicked: false,
                question:
                  "Warum ist unsere Ladestation nicht die ganze Zeit über verfügbar?",
                addendum: "",
                answers: [
                  {
                    symbol: "",
                    answer:
                      "Wir befinden uns derzeit in der Testphase des Projekts (Pilot Phase 0.1), in der es wichtig ist, jeden Schritt sorgfältig zu prüfen. Wir haben uns dafür entschieden, langsam und zuversichtlich vorzugehen. Anfänglich werden wir das E-Pack (Batterie) einmal pro Woche mit voll aufgeladenem Strom bereitstellen und diese Frequenz mit der Zeit schrittweise erhöhen. Wenn das E-Pack nicht an der Ladestation steht, bedeutet dies, dass es im Solarpark aufgeladen wird und so bald wie möglich zurückkehrt.",
                  },
                ],
              },
            ],
          },

          {
            Title: "Testphase: GME-Pilotphase 0.1",
            src: "/Icons/3x/icon_user.svg",

            Questions: [
              {
                isClicked: false,
                question: "Warum sollte ich als Testnutzer*in teilnehmen?",
                addendum: "",
                answers: [
                  {
                    symbol: "",
                    answer:
                      "Wenn du Testnutzer*in wirst, hast du Zugang zu mehreren spannenden Vorteilen, darunter:",
                  },
                  {
                    symbol: "-",
                    answer:
                      "Ein unglaublich attraktives Angebot zum Schnellladen deines E-Autos auf dem 24-Autohof Lutterberg.",
                  },
                  
                  {
                    symbol: "-",
                    answer:
                      "Die Möglichkeit, an der ersten Testphase für eine neue, innovative Ladelösung teilzunehmen.",
                  },
                  {
                    symbol: "-",
                    answer:
                      "Die Chance, den Weg zur emissionsfreien Mobilität zu ebnen.",
                  },
                  {
                    symbol: "-",
                    answer:
                      "Vorteile als Mitglied der GME Charging Community zu erhalten.",
                  },
                  {
                    symbol: "",
                    answer:
                      "Verpasse nicht diese einmalige Gelegenheit, eine wichtige Rolle bei der Gestaltung der Zukunft der nachhaltigen Mobilität zu spielen. Melde dich noch heute an und werde ",
                    link: <HashLink to={'/#heroSection'} onClick={handleClick} style={{color:'#4e672a'}}>Testnutzer*in!</HashLink>,

                  },
                ],
              },
              {
                isClicked: false,
                question:
                  "Wie kann ich Testnutzer*in werden und die Teilnahmegebühr bezahlen?",
                addendum:
                  "**GME muss die Anzahl der Testnutzer*innen begrenzen, da GME nur eine begrenzte Menge an Energie pro Woche zur Verfügung stellen kann. ***Der Preis ist netto.",

                answers: [
                  {
                    symbol: "1.",
                    answer:
                      "Registriere dich ",
                    link: <HashLink to={'/#heroSection'} onClick={handleClick} style={{color:'#4e672a'}}>online</HashLink>,
                    answer1: ' und wähle ein Datum und eine Uhrzeit für die Abholung aus.** '
                    },
                  {
                    symbol: "2.",
                    answer:
                      "Fahre mit deinem E-Fahrzeug zum 24-Autohof Lutterberg zum gewählten Abholtermin. ",
                  },
                  {
                    symbol: "3.",
                    answer:
                      "Am Abholtermin zahlst du die Teilnahmegebühr von 17,50 Euro und erhältst  dafür deine GME-RFID-Karte. Die Bezahlung erfolgt in der Tankstelle des 24-Autohofs Lutterberg. Unsere GME-Mitarbeiter*in ist auch an den Betriebstagen der Ladestation vor Ort, um dich in dieser Phase zu unterstützen. Du findest sie neben der Ladestation, die sich ",
                    link: <HashLink to={'/#location'} style={{color:'#4e672a'}} >hier befindet.</HashLink>,
                  },
                  {
                    symbol: "4.",
                    answer:
                      "Mit deiner GME-RFID-Karte kannst du dein E-Auto sofort aufladen, wenn die GME-Ladestation verfügbar ist. Prüfe die Verfügbarkeit ",
                    link: <HashLink to={'/#status'} style={{color:'#4e672a'}} >hier.</HashLink>,
                    answer1: ' Deine Karte ist 30 Tage lang gültig, um dein E-Fahrzeug an der GME-Ladestation aufzuladen.'
                  },
                  {
                    symbol: "5.",
                    answer:
                      "Um deine Zeit als Testnutzer*in zu verlängern, musst du deine RFID-Karte erneut mit 17,50 Euro aufladen, damit du dein E-Auto weitere 30 Tage lang aufladen kannst.",
                  },
                  {
                    symbol: "",
                    answer:
                      (
                        <div>
                          Die gesamte Testphase wird voraussichtlich 3 Monate dauern, von Juli bis September 2023. Wir laden dich ein, während der gesamten 3 Monate als Testnutzer*in teilzunehmen. <br /> <br />
                          **GME muss die Anzahl der Testnutzer*innen begrenzen, da GME nur eine begrenzte Menge an Energie pro Woche zur Verfügung stellen kann. <br />
                          

                        </div>
                      )
                  },
                ],
              },
              {
                isClicked: false,
                question: "Was werde ich als Testnutzer tun?",
                addendum:
                  '* Der EV-Charger wird während der Testphase mindestens einmal pro Woche zur Verfügung stehen, wobei eine Energiemenge von 300 kWh verfügbar sein wird. Diese Energiemenge wird nach dem Prinzip  "first-come first-serve" verteilt. Sie können hier (LINK) nachsehen, wann die Ladestation (wieder) verfügbar ist.',

                answers: [
                  {
                    symbol: "-",
                    answer:
                      "Lade dein Auto an der GME-EV-Ladestation am 24-Autohof Lutterberg, einer Tankstelle an der Autobahn A7 nördlich von Kassel",
                  },
                  {
                    symbol: "-",
                    answer:
                      "Möglichkeit, eine Umfrage für Kundenfeedback auszufüllen",
                  },
                  {
                    symbol: "-",
                    answer:
                      "Zahle eine Teilnahmegebühr von 17,50 Euro pro Monat, lade dein Auto so oft du willst*",
                  },
                  {
                    symbol: "-",
                    answer:
                      "Durch deine Rolle als Testnutzer trägst du dazu bei, dass die mobile Energielösung von GME reibungslos funktioniert und dir in naher Zukunft als Standard-Mobilitätsdienstleistung zur Verfügung stehen wird.",
                  },
                  {
                    symbol: "-",
                    answer: "Gestalte die Zukunft von EV-Charging Lösungen mit",
                  },
                  {
                    symbol: "",
                    answer: (
                      <div>
                        * Der EV-Charger wird während der Testphase mindestens einmal pro Woche zur Verfügung stehen, wobei eine Energiemenge von 300 kWh verfügbar sein wird. Diese Energiemenge wird nach dem Prinzip ""first-come first-serve"" verteilt. Sie können <HashLink to={'/#status'} style={{color:'#4e672a'}} >hier</HashLink> nachsehen, wann die Ladestation (wieder) verfügbar ist
                      </div>
                    )
                  }
                ],
              },
              {
                isClicked: false,
                question:
                  "Wann wird die Testphase beginnen und enden? Welches ist der frühestmögliche Anmelde- und Ladetermin?",
                addendum: "",
                answers: [
                  {
                    symbol: "-",
                    answer:
                      "Voraussichtlicher Beginn der Testphase: 27. Juli 2023",
                  },
                  {
                    symbol: "-",
                    answer:
                      "Voraussichtliches Ende der Testphase: 30. September 2023",
                  },
                  {
                    symbol: "-",
                    answer:
                      "Früheste Registrierung: Sobald diese ",
                    link: <HashLink to={'/#heroSection'} onClick={handleClick} style={{color:'#4e672a'}}>Registrierung</HashLink>,
                    answer1: ' aktiv ist, kannst du dich als Testnutzer*in anmelden. Sobald wir die volle Kapazität erreicht haben, werden wir die Registrierung (vorübergehend) deaktivieren.'
                  },
                  {
                    symbol: "-",
                    answer:
                      "Frühestmöglicher Tag, an dem du dein E-Fahrzeug aufladen kannst: Wenn die Testphase am 27. Juli beginnt, werden wir die ersten 300 kWh auf dem 24-Autohof Lutterberg zur Verfügung stellen, für spätere Verfügbarkeiten konsultiere bitte ",
                    link: <HashLink to={'/#status'} style={{color:'#4e672a'}} >hier.</HashLink>,
                  },
                ],
              },
            ],
          },
          {
            Title:
              "Wie benutze ich die Ladestation/ Wo finde ich Unterstützung?",
            src: "/Icons/3x/Vectorcharger.svg",

            Questions: [
              {
                isClicked: false,
                question:
                  "Wie und wann kann ich mein Auto am GME EV-Charger am 24-Autohof Lutterberg aufladen?",
                addendum: "",
                answers: [
                  {
                    symbol: "-",
                    answer:
                      "Um den GME EV-Charger nutzen zu können, musst du dich als Testnutzer*in ",
                    link: <HashLink to={'/#heroSection'} onClick={handleClick} style={{color:'#4e672a'}}>registrieren.</HashLink>,
                    },
                  {
                    symbol: "-",
                    answer:
                      "Wenn du einer der GME-Testnutzer*innen bist und die 17,50 Euro Teilnahmegebühr für die 30 Tage bezahlt hast, kannst du dein Elektrofahrzeug so oft aufladen, wie du möchtest und die GME-Ladestation zur ",
                    link: <HashLink to={'/#status'} style={{color:'#4e672a'}} >Verfügung steht .</HashLink>,
                  },
                ],
              },
              {
                isClicked: false,
                question:
                  "Wie kann ich meinen Testzeitraum verlängern über die ersten 30 Tage hinaus?",
                addendum: "",
                answers: [
                  {
                    symbol: "",
                    answer:
                      "Wenn du bereits als Testnutzer*in registriert bist und die ersten 30 Tage deines Testzeitraums vorbei sind, kannst du deine RFID-Karte mit weiteren 17,50 Euro an der 24-Autohof Lutterberg-Tankstelle aufladen, wenn die GME-Ladestation ",
                    link: <HashLink to={'/#status'} style={{color:'#4e672a'}} >verfügbar ist.</HashLink>,
                    answer1: ' Um die Reaktivierung deiner RFID-Karte abzuschließen, zeige diese zusammen mit deiner Quittung der GME-Mitarbeiter*in vor Ort. Deine RFID-Karte wird dann für weitere 30 Tage aktiviert.   '
                  },
                ],
              },
              {
                isClicked: false,
                question:
                  "Wie kann ich überprüfen, ob die GME Ladestation verfügbar ist und über genügend Energie verfügt?",
                addendum: "",
                answers: [
                  {
                    symbol: "",
                    answer:
                      "Du kannst die Verfügbarkeit der GME Ladestation ",
                    link: <HashLink to={'/#status'} style={{color:'#4e672a'}} >hier</HashLink>,
                    answer1: ' überprüfen und sehen, wie viele kWh zum Laden übrig sind.'  
                  },
                ],
              },
              {
                isClicked: false,
                question:
                  "Ich habe eine Karte gekauft, hatte aber keine Gelegenheit, sie im laufenden Monat zu benutzen. Kann ich die Karte im nächsten Monat verwenden?",
                addendum: "",
                answers: [
                  {
                    symbol: "",
                    answer:
                      "Du kannst die Karte für einen Zeitraum von 30 Tagen verwenden. Danach wird sie automatisch deaktiviert. Um deinen Testzeitraum zu verlängern, musst du die Teilnahmegebühr erneut an der 24-Authof Lutterberg Servicestation bezahlen und die Reaktivierung deiner RFID-Karte bei den GME-Mitarbeitern vor Ort durchführen.",
                  },
                ],
              },
              {
                isClicked: false,
                question:
                  "Wo erhalte ich Unterstützung, wenn die Ladestation nicht funktioniert?",
                addendum: "",
                answers: [
                  {
                    symbol: "",
                    answer:
                      "Bitte wende dich an unsere GME-Mitarbeiter vor Ort, die dir gerne weiterhelfen. Du könntest auch vor Ort sein, wenn die Ladestation gerade nicht verfügbar ist, weil das E-Pack aufgeladen wird. Bitte siehe ",
                    link: <HashLink to={'/#status'} style={{color:'#4e672a'}} >hier</HashLink>,
                    answer1: ' nach, wann die Ladestation wieder verfügbar ist.'
                  },
                ],
              },
            ],
          },
          {
            Title: "GME Zukunft / Ausblick",
            src: "/Icons/3x/Vectorbulb.svg",

            Questions: [
              {
                isClicked: false,
                question: "Wie geht es weiter?",
                addendum: "",
                answers: [
                  {
                    symbol: "",
                    answer:
                      "Unser Ziel am 24-Autohof Lutterberg ist es, unseren Kunden*innen nach Abschluss der Testphase durchgängig Ökostrom anzubieten, damit sie ihren Ladevorgang unabhängig und wie gewohnt ausführen können. Sobald die Testphase erfolgreich verlaufen ist, werden wir dich zeitnah über unsere nächsten Schritte und Standorte informieren. Bleib auf dem Laufenden, indem du uns auf ",
                    link: (
                      <>
                          <a href="https://www.linkedin.com/company/greenboxmobile/" style={{color:'#4e672a'}} target="_blank">LinkedIn</a>
                      </>
                    ),
                    answer1: ' folgst und dich für unseren Newsletter anmeldest.'

                  },
                ],
              },
            ],
          },
        ],
      },
    },
  });

  function Frequent_Question({ question, answers }) {
    function AnswerList() {
      return (
        <div className="answerboundary font1 fontsizeQnA ">
          {" "}
          {answers.map((the_answer, index) => (
            <div key={index}>
              {" "}
              {the_answer.symbol} {the_answer.answer} {the_answer.link}{the_answer.answer1}{" "}
            </div>
          ))}{" "}
        </div>
      );
    }

    function toggleQuestion(question) {
      if (currentQuestion === question) {
        setcurrentQuestion("");
      }
      if (currentQuestion !== question) {
        setcurrentQuestion(question);
      }
    }

    function isVeryLastQuestion_EN(question) {
      if (
        question ===
        faqs.language.EN.Subcategories.at(-1).Questions.at(-1).question
      ) {
        return true;
      }

      return false;
    }

    function isVeryLastQuestion_DE(question) {
      if (
        question ===
        faqs.language.DE.Subcategories.at(-1).Questions.at(-1).question
      ) {
        return true;
      }

      return false;
    }

    function IsVeryLastQuestion(question) {
      if (isVeryLastQuestion_EN(question) === true) {
        return true;
      }
      if (isVeryLastQuestion_DE(question) === true) {
        return true;
      }

      return false;
    }

    return (
      <div className="overflowingtext">
        <h2 className="DarkBigGreenLetters ML20-faq font1 fontsizeQnA">
          {" "}
          <div
            onClick={() => {
              toggleQuestion(question);
            }}
          >
            {question}
            {currentQuestion !== question && <MdKeyboardArrowDown />}{" "}
            {currentQuestion === question && <MdKeyboardArrowUp />}
          </div>{" "}
        </h2>{" "}
        {currentQuestion === question && (
          <div className="ML20-faq"> {answers.length > 0 && <AnswerList />} </div>
        )}
        {!IsVeryLastQuestion(question) && (
          <div className="questionBarrier ML20-faq"> </div>
        )}
      </div>
    );
  }

  function SubCategory({ Title, List, src }) {
    return (
      <div>
        {" "}
        <h2 className="ML20-faq">
          {" "}
          <div className="categorybox BigGreenLetters font1 fontsizeSC">
            <img className="subcaticon somepadding" src={src} alt="Icon 1" />{" "}
            {Title}{" "}
          </div>
        </h2>{" "}
        {List.map((FreqQuestion, index) => (
          <Frequent_Question
            question={FreqQuestion.question}
            answers={FreqQuestion.answers}
            key={index}
          />
        ))}{" "}
      </div>
    );
  }

  function AllSubCategories({ languageFAQ }) {
    return (
      <div>
        {" "}
        {languageFAQ.map((subcategory, index) => (
          <div key={index}>
            {" "}
            <SubCategory
              Title={subcategory.Title}
              List={subcategory.Questions}
              src={subcategory.src}
            />{" "}
          </div>
        ))}{" "}
      </div>
    );
  }

  return (
    <div className="faq-section">
      
      <div className="faq-text">
       
        Frequently Asked Questions
      </div>
      <div className="categories-faq">
        {language === "EN" && (
          <AllSubCategories languageFAQ={faqs.language.EN.Subcategories} />
        )}
        {language === "DE" && (
          <AllSubCategories languageFAQ={faqs.language.DE.Subcategories} />
        )}
      </div>
    </div>
  );
}
