import { Link } from "react-router-dom"
import { useState } from "react"
import { animateScroll as scroll } from "react-scroll"



const TermsAndConditionsNew = ({language}) => {
  const [sendLanguage, setSendLanguage] = useState(language)
  const scrollToAnchor = (sendLanguage) => {
    scroll.scrollTo('status', {
      smooth: true,
      containerId: 'scroll-container',
    })  
    const url = new URL('/#status', window.location.origin)
    url.searchParams.set('sendLanguage', sendLanguage)
    window.open(url.href, '_blank')
  }

  if (language === 'DE'){
    return( 
      <div className="terms-conditions">
        
        <ul className='terms-text'>
          <p className="main-text">
            Allgemeine Geschäftsbedingungen (AGBs)
          </p>
          Bitte lesen Sie diese Allgemeinen Geschäftsbedingungen (“Geschäftsbedingungen”, “Bedingungen”) sorgfältig durch, bevor Sie www.greenboxmobile.app (“Website”, “Service”), betrieben von Greenbox Mobile Energy GmbH (“GME”), nutzen. 
          <br/>
          <br/>
          <p style={{fontWeight:'600', color: 'rgba(116, 153, 63, 1)'}}>Bedingungen für die Nutzung </p>
          
          Durch die Nutzung dieser Website bestätigen Sie, dass Sie diese Bedingungen gelesen und überprüft haben und dass Sie mit ihrer Einhaltung einverstanden sind. Wenn Sie nicht an diese Bedingungen gebunden sein wollen, werden Sie gebeten, die Website zu verlassen. GME gewährt die Nutzung und den Zugang zu dieser Website, ihren Produkten und Dienstleistungen nur denjenigen, die diese Bedingungen akzeptiert haben          <br />
          <br />
          <p style={{fontWeight:'600', color: 'rgba(116, 153, 63, 1)'}}>Dienste und Funktionen</p>

          Die Website bietet den Besuchern die Möglichkeit, sich als Proband (Test user) für die GME Pilot Phase 0.1 anzumelden und persönliche Daten wie Name, Geburtsdatum und E-Mail-Adresse anzugeben. Die gesammelten Daten werden ausschließlich für die Durchführung der Pilot Phase 0.1 verwendet und nicht an Dritte weitergegeben.           <br />
          <br />
          <p style={{fontWeight:'600', color: 'rgba(116, 153, 63, 1)'}}>Datenschutzpolitik</p>
         
          Bevor Sie mit der Nutzung unserer Website fortfahren, empfehlen wir Ihnen, unsere <Link to={{ pathname: '/datenschutz',
            state: { language },
            search: `?language=${language}`
          }}
          target="_blank"
          rel="noopener noreferrer"
          className="terms-text"> Datenschutz </Link>bezüglich der Erhebung von Nutzerdaten zu lesen. Sie wird Ihnen helfen, unsere Praktiken besser zu verstehen.          <br />
          <br />
          <p style={{fontWeight:'600', color: 'rgba(116, 153, 63, 1)'}}>Einwilligung zur Verwendung personenbezogener Daten</p>
              
          Durch die Nutzung der Website und das Hinterlassen personenbezogener Daten erklärt der Nutzer seine ausdrückliche Einwilligung zur Verwendung dieser Daten für die in diesen AGBs beschriebenen Zwecke.
          <br />
          <br />
          <p style={{fontWeight:'600', color: 'rgba(116, 153, 63, 1)'}}>Geistiges Eigentum</p>
        
          Sie erklären sich damit einverstanden, dass alle Materialien, Produkte und Dienstleistungen, die auf dieser Website angeboten werden, Eigentum von GME, seinen verbundenen Unternehmen, Direktoren, leitenden Angestellten, Mitarbeitern, Vertretern, Lieferanten oder Lizenzgebern sind, einschließlich aller Urheberrechte, Geschäftsgeheimnisse, Marken, Patente und anderen geistigen Eigentumsrechte. Sie erklären sich außerdem damit einverstanden, das geistige Eigentum von GME in keiner Weise zu reproduzieren oder weiterzuverbreiten, auch nicht in elektronischer oder digitaler Form oder durch neue Markenregistrierungen.           <br />
          <br />
          <p style={{fontWeight:'600', color: 'rgba(116, 153, 63, 1)'}}>Benutzerregistrierung </p>
          
          Als Benutzer dieser Website werden Sie möglicherweise aufgefordert, sich bei uns zu registrieren und private Informationen anzugeben. Sie sind dafür verantwortlich, dass diese Informationen korrekt sind, und Sie sind dafür verantwortlich, die Sicherheit Ihrer Identifikationsdaten zu gewährleisten.  
          Wenn Sie der Meinung sind, dass es Probleme mit der Sicherheit Ihrer Daten auf der Website gibt, informieren Sie uns bitte unverzüglich, damit wir uns entsprechend darum kümmern können. 
          Wir behalten uns das Recht vor, Konten zu kündigen, Inhalte zu bearbeiten oder zu entfernen und Registrierungen nach unserem alleinigen Ermessen zu stornieren.           <br />
          <br />
          <p style={{fontWeight:'600', color: 'rgba(116, 153, 63, 1)'}}>Geltendes Recht</p>  
        
          Durch den Besuch dieser Website erklären Sie sich damit einverstanden, dass die Gesetze der Bundesrepublik Deutschland, ohne Rücksicht auf kollisionsrechtliche Grundsätze, diese Bedingungen oder jegliche Streitigkeiten jeglicher Art, die zwischen GME oder seinen Geschäftspartnern und Mitarbeitern und Ihnen entstehen könnten, regeln.           <br />
          <br />
          <p style={{fontWeight:'600', color: 'rgba(116, 153, 63, 1)'}}>Rechtsstreitigkeiten</p>

          Alle Streitigkeiten, die in irgendeiner Weise mit Ihrem Besuch auf dieser Website zusammenhängen, werden von einem Gericht in Berlin geschlichtet, und Sie stimmen der ausschließlichen Zuständigkeit und diesem Gerichtsstand zu.          <br />
          <br />
          <p style={{fontWeight:'600', color: 'rgba(116, 153, 63, 1)'}}>Entschädigung</p>
          
          Sie erklären sich damit einverstanden, GME und seine Partner zu entschädigen und GME von rechtlichen Ansprüchen und Forderungen freizustellen, die sich aus Ihrer Nutzung oder Ihrem Missbrauch unserer Dienste ergeben könnten. Wir behalten uns das Recht vor, unseren eigenen Rechtsbeistand auszuwählen.           <br />
          <br />
          <p style={{fontWeight:'600', color: 'rgba(116, 153, 63, 1)'}}>Einschränkung der Haftung</p>
          
          GME haftet nicht für Schäden, die Ihnen durch die missbräuchliche Nutzung unserer Website entstehen können. 

GME behält sich das Recht vor, diese Bedingungen jederzeit zu bearbeiten, zu modifizieren und zu ändern. Wir werden unsere Nutzer über diese Änderungen per E-Mail informieren. Diese Vereinbarung ist eine Abmachung zwischen GME und dem Benutzer, und sie ersetzt alle früheren Vereinbarungen über die Nutzung dieser Website.           <br />
         
          <br />
          <br />
          <p className="main-text">
            Leistungsbeschreibung der Testnutzung der mobilen Greenbox Ladesäule:          <br />
          </p>
          <br />
          Greenbox Mobile Energy GmbH erbringt als selbständiges Unternehmen folgende Leistungen gegenüber dem registrierten Testnutzer*in:
          <br />
          <br />
          <li>
          Die Greenbox Mobile Energy GmbH ermöglicht es Webseitenbesuchern, sich als Testnutzer auf ihrer Website zu registrieren, um an der Testphase zur Nutzung einer Elektroauto-Ladestation am 24-Autohof Lutterberg teilzunehmen. Die Testnutzer zahlen eine Teilnahmegebühr in Höhe von 17,50 Euro inklusive 19% MwSt an der Kasse des Shops des 24-Autohof Lutterberg und erhalten im Gegenzug eine Greenbox RFID-Karte. Mit dieser RFID-Karte können die Testnutzer die Greenbox-Ladestation bedienen und 30 Tage lang ihr Elektroauto laden, wenn die Ladestation in Betrieb ist. Da die Ladestation durch ein mobiles Batteriespeichersystem mit Energie versorgt wird, ist die Ladestation nur in Betrieb, wenn das Batteriespeichersystem angeschlossen ist und über genügend Energie verfügt, um die Ladestation zu versorgen. Pro Zyklus stehen den Testnutzern 300 kWh zur Verfügung, die nach dem Prinzip "first-come, first-serve" zur Beladung ihrer Elektroautos zur Verfügung stehen. Greenbox Mobile Energy rechnet in der ersten Testphase mit einem wöchentlichen Zyklus, bei dem den Testnutzern einmal pro Woche 300 kWh zur Verfügung stehen. Nach 30 Tagen können die Testnutzer durch erneute Zahlung von 17,50 Euro inklusive 19% MwSt ihren Teilnahmezeitraum um weitere 30 Tage verlängern. Die Verfügbarkeit der mobilen Ladestation wird immer auf dieser <a 
              href="/#status" 
              onClick={(el) => {
                el.preventDefault()
                scrollToAnchor(sendLanguage)
              }} 
              target="_blank" 
              rel="noopener noreferrer">Website</a>  angezeigt. 
          </li>
          <br />
          <li>
            Die Teilnahmegebühr und die RFID-Karte sind 30 Tage lang gültig. Das Startdatum ist der Zeitpunkt an dem die Testnutzer*in die Teilnahmegebühr in Höhe von 17,50 Euro inklusive 19% MwSt am Standort 24-Authof Lutterberg entrichtet und die Registrierung mit dem Greenbox Personal vor Ort abschließt. Nach 30 Tagen endet die Teilnahme automatisch außer die Testnutzer*in entscheidet sich die Teilnahme um weitere 30 Tage zu verlängern. Die Verlängerung um weitere 30 Tage findet statt, sobald die Testnutzer*in erneut eine Teilnahmegebühr von 17,50 Euro inklusive 19% MwSt am Standort 24-Authof Lutterberg entrichtet und die Verlängerung mit dem Greenbox Personal vor Ort abschließt. 
            </li>
          <br />
          <li>
            Während der Greenbox Moblie Energy (GME) Pilotphase 0.1 wird GME zusammen mit einer begrenzten Anzahl von Testnutzer*innen sein mobiles EV-Ladegerät auf dem 24-Autohof Lutterberg während einer begrenzten Zeitspanne testen. 
          </li>
          <br />
          <li>
            Während dieser Pilotphase wird GME voraussichtlich 300 kWh pro Woche über einen Zeitraum von 2-3 Monaten ab Juli 2023 zur Verfügung stellen. 
          </li>
          <br />
          <li>
            Die 300kWh werden den Testnutzer*innen nach dem Prinzip " first-come, first-serve" zur Verfügung stehen.
          </li>
          <br />
          <li>
            Testnutzer*innen sind eine begrenzte Anzahl von Teilnehmer*innen, die sich online registriert, eine Teilnahmegebühr entrichtet und eine Greenbox Mobile Energy RFID-Karte erworben haben. 
          </li>
          <br />
          <li>
            Verfügbare Zeiten/Daten für den GME EV-Charger finden die Testnutzer*innen <a 
              href="/#status" 
              onClick={(el) => {
                el.preventDefault()
                scrollToAnchor(sendLanguage)
              }} 
              className="terms-text"
              target="_blank" 
              rel="noopener noreferrer">hier</a> 
            . 
          </li>
          <br />
          <li>
            Die Nutzung des GME EV-Chargers ist nur mit einer GME RFID Karte möglich. 
          </li>
          <br />
          <li>
            Die GME RFID-Karte kann ausschließlich vor Ort in der Servicestation des 24-Autohofs Lutterberg erworben werden. Die Bezahlung ist mit Karte oder bar möglich. 
          </li>
          <br />
          <li>
            GME hat für die Testphase nur eine begrenzte Anzahl von RFID-Karten zur Verfügung, die nach dem Prinzip "first-come,first-serve" verteilt werden. 
          </li>
          <br />
          <li>
            Falls GME eine zweite Charge von RFID-Karten freigibt, um die Zahl der Testnutzer*innen zu erhöhen, werden die Informationen online auf dieser <Link to={{
              pathname: '/',
              search: `?sendLanguage=${sendLanguage}`
            }}
            rel="noopener noreferrer"
            target="_blank"
            className="terms-text"
            >Website</Link> veröffentlicht . 
          </li>
          <br />
          <li>
            Die Teilnahmegebühr wird auf 17,50 Euro/Monat 19 % MwSt. (inklusive) festgesetzt. 
          </li>
          <br />
          <li>
            Nach erfolgter Registrierung und Bezahlung erhält die Testnutzer*in eine GME-RFID-Karte, mit dieser kann der GME-EV-Charger auf dem 24-Autohof Lutterberg zum Aufladen des Fahrzeugs genutzt werden. Die Karte kann 30 Tage lang ab Aktivierung verwendet werden. Eine automatische Erneuerung der Nutzung der RFID-Karte ist nicht vorgesehen. 
          </li>
          <br />
          <li>
            Um die Teilnahme als Testnutzer im nächsten Monat zu verlängern, muss der Testnutzer die Teilnahmegebühr von 17,50 Euro (19% MwSt. inklusive) erneut bezahlen. Dann wird die RFID-Karte für weitere 30 Tage aktiviert.  
          </li>
          <br />
          <li>
            Zu Beginn des Pilotprojekts wird die Anzahl der Testnutzer*innen/RFID-Karten auf maximal 10 Personen pro Testlauf begrenzt, wobei 300 kWh pro Testlauf zur Verfügung stehen. Wenn die 300 kWh bereits von weniger Personen verbraucht werden, kann die Begrenzung noch geringer ausfallen. Die Anzahl der Testnutzer*innen, die sich für jeden Testlauf anmelden können, beträgt vier. Diese Plätze werden nach dem Prinzip "first-come, first-serve" vergeben. Zusätzlich zu diesen vier Plätzen können sich Personen vor Ort als Testnutzer*innen registrieren lassen, um ihr Elektrofahrzeug mit den verbleibenden kWh aufzuladen (hier gilt auch wieder das Prinzip: "first-come,first-serve"). 
          </li>
          <br />
          <li>
            GME ist nur verantwortlich für technische Probleme oder andere Umstände, die die Testnutzer*in daran hindern, die GME RFID-Karte zum Aufladen des E-Fahrzeugs an dem GME EV-Charger am 24-Autohof Lutterberg zu verwenden solange diese während der angegebenen Testlauf Zeiten auftreten. Außerhalb der Testlaufzeiten ist GME nicht für technische Probleme im Zusammenhang mit der GME RFID Karte verantwortlich.
          </li>
          <br />
          <li>
            Sollte die Testnutzer*in nicht in der Lage sein, die aktivierte GME RFID-Karte mindestens einmal während der aktiven 30 Tage zum Aufladen des E-Fahrzeugs zu nutzen, erstattet GME der Testnutzer*in die Teilnahmegebühr von 17,50 Euro zurück. Hierfür muss der Testnutzer mit GME in Kontakt treten (<a href="mailto:pilot@greenboxmobile.app">E-Mail</a>) und die RFID-Kartennummer sowie den Kaufbeleg einreichen. Sollte die Karte tatsächlich nicht zum Einsatz gekommen sein, leitet GME die Rückerstattung des Kaufpreises ein.  
          </li>
          <br />
          <li>
            Die Rückerstattung erfolgt als Banküberweisung, und wird innerhalb von 30 Tagen, nachdem die Testnutzer*in GME über das Problem informiert hat, bearbeitet. 
          </li>
          <br />
          <li>
            GME haftet nicht für andere Kosten oder Schäden, die dem Testnutzer dadurch entstehen, dass er die GME RFID-Karte nicht zum Aufladen seines Elektrofahrzeugs verwenden kann. 
          </li>
          <br />
          <li>
            GME wird der registrierten Testnutzer*in wichtige Informationen zur Testphase per E-Mail zusenden, u.a. die Schritte, die als Testnutzer*in der GME-Pilotphase 0.1 zu befolgen sind, einschließlich der Zahlung der Teilnahmegebühr und des weiteren Vorgehens nach dem ersten Monat der Testphase, Aufforderungen zur Teilnahme an Nutzerumfragen und nach Abschluss der Pilotphase 0.1 weitere Informationen zur nächsten Phase von GME.  
          </li>
          <br />
          <li>
            Der Testnutzer kann seine Einwilligung jederzeit ohne Angabe von Gründen und ohne, dass ihm Nachteile entstehen mit Wirkung für die Zukunft gegenüber der Greenbox Mobile Energy GmbH per E-Mail an <a href="mailto:pilot@greenboxmobile.app">E-Mail</a>  widerrufen. Weitere Informationen zum Datenschutz finden Sie in unserer <Link to={{ pathname: '/datenschutz',
            state: { language },
            search: `?language=${language}`
          }}
          target="_blank"
          rel="noopener noreferrer"
          className="terms-text">Datenschutz.</Link>
          </li>
        </ul>
      </div>
     )
  } else {
    return( 
      <div className="terms-conditions">
        
        <ul className='terms-text'>
        <p className="main-text">
          Terms & Conditions
        </p>
        <br/>
        Please read these terms and conditions (“terms and conditions”, “terms”) carefully before using www.greenboxmobile.app (“website”, “service”) operated by Greenbox Mobile Energy GmbH (“GME”).   
          <br/>
          <br/>
           <p style={{fontWeight:'600', color: 'rgba(116, 153, 63, 1)'}}>Conditions of use </p>
         
        
          By using this website, you certify that you have read and reviewed these terms and that you agree to comply with them. If you do not want to be bound by these terms, you are advised to leave the website accordingly. GME only grants use and access of this website, its products, and its services to those who have accepted its terms.          <br />
          The Website offers visitors the opportunity to register as a test user for the GME Pilot Phase 0.1 and to provide personal data such as name, date of birth and e-mail address. The data collected will be used exclusively for the implementation of Pilot Phase 0.1 and will not be passed on to third parties. 
          <br />
          <br />
          <p style={{fontWeight:'600', color: 'rgba(116, 153, 63, 1)'}}>Services and functions</p>
        
          The Website offers visitors the opportunity to register as a test user for the GME Pilot Phase 0.1 and to provide personal data such as name, date of birth and e-mail address. The data collected will be used exclusively for the implementation of Pilot Phase 0.1 and will not be passed on to third parties.            <br />
          <br />

          <p style={{fontWeight:'600', color: 'rgba(116, 153, 63, 1)'}}>Privacy policy</p> 
         
          Before you continue using our website, we advise you to read our <Link to={{
            pathname: '/datenschutz',
            state: { language },
            search: `?language=${language}`
          }}
          target="_blank"
          rel="noopener noreferrer"
          className="terms-text">Privacy Policy </Link>
          regarding our user data collection. It will help you better understand our practices.           <br />
          <br />
          <p style={{fontWeight:'600', color: 'rgba(116, 153, 63, 1)'}}>Consent to the use of personal data </p>
        
          By using the website and leaving personal data, the user expressly consents to the use of this data for the purposes described in these GTCs.          <br />
          <br />
          <p style={{fontWeight:'600', color: 'rgba(116, 153, 63, 1)'}}>Intellectual property   </p>
       
          You agree that all materials, products, and services provided on this website are the property of GME, its affiliates, directors, officers, employees, agents, suppliers, or licensors including all copyrights, trade secrets, trademarks, patents, and other intellectual property. You also agree that you will not reproduce or redistribute GME’s intellectual property in any way, including electronic, digital, or new trademark registrations.          <br />
          <br />
          <p style={{fontWeight:'600', color: 'rgba(116, 153, 63, 1)'}}>User registration</p>
          
          As a user of this website, you may be asked to register with us and provide private information. You are responsible for ensuring the accuracy of this information, and you are responsible for maintaining the safety and security of your identifying information.  
          If you think there are any possible issues regarding the security of your user data on the website, inform us immediately so we may address them accordingly. 
          We reserve all rights to terminate registrations, edit or remove content and cancel orders at our sole discretion.          <br />
          <br />
          <p style={{fontWeight:'600', color: 'rgba(116, 153, 63, 1)'}}> Applicable law</p>
          By visiting this website, you agree that the laws of the Federal Republic of Germany, without regard to principles of conflict laws, will govern these terms and conditions, or any dispute of any sort that might come between GME or its business partners and associates and you.   
          <br />
          <br />
          <p style={{fontWeight:'600', color: 'rgba(116, 153, 63, 1)'}}>Disputes </p>
      
          Any dispute related in any way to your visit to this website or to products you purchase from us shall be arbitrated by state or federal court in Berlin and you consent to exclusive jurisdiction and venue of such courts.        
          <br />
          <br />
          <p style={{fontWeight:'600', color: 'rgba(116, 153, 63, 1)'}}>Indemnification </p>

          You agree to indemnify GME and its affiliates and hold GME harmless against legal claims and demands that may arise from your use or misuse of our services. We reserve the right to select our own legal counsel.         
          <br />
          <br />
          <p style={{fontWeight:'600', color: 'rgba(116, 153, 63, 1)'}}>Limitation on liability</p>
          GME is not liable for any damages that may occur to you as a result of your misuse of our website. 

          GME reserves the right to edit, modify, and change these terms at any time. We shall let our users know of these changes through electronic mail. This Agreement is an understanding between GME and the user, and it supersedes and replaces all prior agreements regarding the use of this website. 

          <br />
          <br />
          <br />
          <p className="main-text">
            Service description of the test use of the mobile Greenbox charging station
            </p>
          <br />
          Greenbox Mobile Energy GmbH provides the following services as an independent contractor to the registered test user:  
          <br />
          <br />
          <li >
          Greenbox Mobile Energy GmbH enables website visitors to register as test users on its website in order to participate in the test phase for the use of an electric car charging station at 24-Autohof Lutterberg. The test users pay a participation fee of 17,5 euros plus 19% VAT (included) at the checkout of the store of 24-Autohof Lutterberg and receive a Greenbox RFID card in return. With this RFID card, the test users can operate the Greenbox charging station and charge their electric car for 30 days when the charging station is in operation. Since the charging station powered by a mobile battery storage system, the charging station is only in operation when the battery storage system is connected and has enough energy to power the charging station. Per cycle, test users have 300 kWh available to charge their electric cars on a first-come, first-served basis. Greenbox Mobile Energy anticipates a weekly cycle in the initial test phase, with 300 kWh available to test users once per week. After 30 days, test users can extend their participation period by another 30 days by paying another 17,5 euros 19% VAT (included). 
          </li>
          <br />
          <li>
            The participation fee and the RFID card are valid for 30 days. The start date is the date on which the test user pays the participation fee of 17,5 euros 19% VAT (included) at the location 24-Authof Lutterberg and completes the registration with the Greenbox staff on site. After 30 days the participation ends automatically unless the test user decides to extend the participation for another 30 days. The extension for another 30 days will take place as soon as the test user pays again a participation fee of 17,5 euros 19% VAT (included) at the location 24-Authof Lutterberg and completes the extension with the Greenbox staff on site. 
          </li>
          <br />
          <li>
            During the Greenbox Moblie Energy (GME) pilot phase 0.1, GME will test its mobile EV charger together with a limited number of test users* at the 24-Autohof Lutterberg site during a limited period of time.  
          </li>
          <br />
          <li>
            During this pilot phase, GME is expected to provide 300kWh per week over a 2-3 month period starting in July 2023.  
          </li>
          <br />
          <li>
            The 300kWh will be available to test users* on a " first-come, first-serve" basis. 
          </li>
          <br />
          <li>
            Test users are a limited number of participants who have registered online, paid a participation fee and purchased a Greenbox Mobile Energy RFID card.  
          </li>
          <br />
          <li>
            Available times/dates for the GME EV-Charger can be found by test users <a href="/#status" className="terms-text" scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })} target="_blank" rel="noopener noreferrer">here</a>.  
          </li>
          <br />
          <li>
            The use of the GME EV-Charger is only possible with a GME RFID card.  
          </li>
          <br />
          <li>
            The GME RFID card can only be purchased on site at the service station of the 24-Autohof Lutterberg. Payment is possible by card or cash.  
          </li>
          <br />
          <li>
            GME has only a limited number of RFID cards available for the test phase, which will be distributed on a first-come,first-serve basis.  
          </li>
          <br />
          <li>
            If GME releases a second batch of RFID cards to increase the number of test users*, the information will be published online on this <Link to='/' target="_blank" rel='noopener noreferrer' className="terms-text">website</Link>.  
          </li>
          <br />
          <li>
            The participation fee is set at 17,5 euros (19% VAT included).  
          </li>
          <br />
          <li>
            After registration and payment, the test user* will receive a GME RFID card, which can be used to charge the GME EV Charger at the 24-Autohof Lutterberg. The card can be used for 30 days from activation. Automatic renewal of the use of the RFID card is not provided.  
          </li>
          <br />
          <li>
            To renew participation as a test user in the next month, the test user must pay the participation fee of 20 euros +19% VAT again. Then the RFID card will be activated for another 30 days.  
          </li>
          <br />
          <li>
            At the beginning of the pilot project, the number of test users/RFID cards will be limited to a maximum of 10 people per test run, with 300 kWh available per test run. If the 300 kWh is already consumed by fewer people, the limit may be even lower. The number of test users* who can sign up for each test run is four. These places are allocated on a first-come, first-serve basis. In addition to these four places, people can register on site as test users to charge their electric vehicle with the remaining kWh (here again the principle: "first-come,first-serve" applies).  
          </li>
          <br />
          <li>
            GME is only responsible for technical problems or other circumstances that prevent the test user from using the GME RFID card to charge the electric vehicle at the GME EV Charger at 24-Autohof Lutterberg as long as they occur during the specified test run times. Outside the test run times, GME is not responsible for technical problems related to the GME RFID card.   
          </li>
          <br />
          <li>
            If the test user is not able to use the activated GME RFID card to charge the e-vehicle at least once during the active 30 days, GME will refund the participation fee of 17,5 euros to the test user. To do this, the test user must contact GME (<a href="mailto:pilot@greenboxmobile.app">Email</a>) and submit the RFID card number and proof of purchase. If the card is not actually used, GME will initiate a refund of the purchase price.   
          </li>
          <br />
          <li>
            The refund will be made as a bank transfer, and will be processed within 30 days after the test user* has informed GME about the problem.  
          </li>
          <br />
          <li>
            GME will not be liable for any other costs or damages incurred by the test user as a result of not being able to use the GME RFID card to charge their electric vehicle.  
          </li>
          <br />
          <li>
            GME will email the registered test user* important information about the test phase, including the steps to follow as a test user of GME Pilot Phase 0.1, including how to pay the participation fee and what to do after the first 30 days of the test phase, prompts to participate in user surveys, and further information about the next phase of GME after the completion of Pilot Phase 0.1.   
          </li>
          <br />
          <li>
          The test user can revoke his consent at any time without giving reasons and without incurring any disadvantages for the future vis-à-vis Greenbox Mobile Energy GmbH by e-mail to <a href="mailto:pilot@greenboxmobile.app">Email</a>. Further information on data protection can be found in our <Link to={{
            pathname: '/datenschutz',
            state: { language },
            search: `?language=${language}`
          }}
          target="_blank"
          rel="noopener noreferrer"
          className="terms-text">Privacy Policy.</Link>
          </li>
        </ul>
      </div>
     )
  }
 

}
export default TermsAndConditionsNew