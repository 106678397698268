import './giveFeedback.css'
export default function GiveFeedback({scrollToFeedbackSection, language}){
  const getText = ()=>{
    if (language === 'DE'){
      return 'Gib uns Feedback'
    }
    return 'Give Feedback'
  }
  return (
    <div className="secondary-button12">
        <a className="frame-14 " onClick={scrollToFeedbackSection}>
          <p className='give-us-feedback' style={{margin:0}}>{getText()}</p>
        </a>
      
    </div>    
  )
}

