import './registrationButton.css';

export default function RegistrationButton({ onRegistrationChange, registration, language, availableDates }) {

  const handleClick = () => {
    const updatedRegistration = true;
    onRegistrationChange(updatedRegistration);
  };
  const getText = () =>{
    if (language === 'DE'){
      return 'Werde Testnutzer*in'
    }
    return 'Become a test user'
  }
  const getTextNotAvailable = () =>{
    if (language === 'DE'){
      return 'Zurzeit keine Registrierung möglich'
    }
    return 'Currently no registration possible'
  }


  return (
    <div className="primary-button">
      {availableDates.length !== 0 ? (
        <div className="frame-41">
          <button className="become-atest-user" onClick={handleClick}>
          {getText()}
          </button>
        </div>
      ):(
        <div className="frame-41" style={{backgroundColor:'grey'}}>
          <button disabled className="become-atest-user"  >
          {getTextNotAvailable()}
          </button>
        </div>
      )}
    </div>
  );
}
