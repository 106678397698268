import  '../header/header.css'

import { useState } from 'react'
import { Link } from 'react-router-dom'

export const PressHeader = (props)=>{
  
  const [selectedLanguage, setSelectedLanguage] = useState(props.language||'EN')
  console.log(selectedLanguage)
  const handleLanguageChange = (language) => {
    setSelectedLanguage(language)
    props.onLanguageChange(language)
    localStorage.setItem('selectedLanguage', language)
  }

  const getText = () => {
    if (selectedLanguage === 'DE') {
      return 'Hauptseite';
    }
    return 'Main Page'
  }
  return (
    <div className='rectangle'>
      <div>
        <img className='iconGME' src='/Icons/3x/LogoRGB-GBME.svg' alt=''/>
      </div>
      

      <div className='language-selection'>
        <div className="primary-button">
          <div className="frame-41">
            <Link
              to={{
                pathname: "/",
                state: { selectedLanguage },
              }}
              rel="noopener noreferrer"
              className="links"
            >
            <div className='become-atest-user'>{getText()}</div> 
            </Link>
          </div>
        </div>
        <button 
          className={selectedLanguage === 'DE' ? 'language-button selected' : 'language-button'}
          onClick={() => handleLanguageChange('DE')}
        >DE</button>

        <button 
          className={selectedLanguage === 'EN' ? 'language-button selected' : 'language-button'}
          onClick={() => handleLanguageChange('EN')}
        >EN</button>
      </div>
      
    </div>
  )
}