import "./How.css";
import { useState } from "react";
import { HashLink } from "react-router-hash-link";


export default function How({ language, onClickToRegistration }) {
  const handleClick = () =>{
    const registrationProp=true
    onClickToRegistration(registrationProp)
    console.log(registrationProp)
  }
  const [slidesEN, setslidesEN] = useState([
    {
      text: `Become a test user.`,
      text1: 'Click',
      index: 1,
      title: "Registration",
      link: <HashLink to={'/#heroSection'} onClick={handleClick} style={{color:'#4e672a'}}>here.</HashLink>,
      img: '/Icons/3x/Number=1.svg',
      
    },
    {
      text: "Buy your RFID card for a one time payment of 17,50 Euros.",
      text1: "This card will be valid for unlimited* charging for 30 days.",
      text2: '*In this testing phase the charger will be available once a week.',
      index: 2,
      title: "Get your Greenbox RFID card",
      link: "",
      img: '/Icons/3x/Number=2.svg'
    },
    {
      text: "Park your car in the designated area next to the EV-Charger.",
      text1: 'Use your RFID card to charge your car.    ',
      index: 3,
      title: "Charge",
      link: "",
      img: '/Icons/3x/Number=3.svg'
    },
    {
      text: "Thank you for charging! ",
      text1:"Check out the availabilty of the EV-Charger for your next charge",
      index: 4,
      title: "Next Time",
      link: <HashLink to={'/#status'} style={{color:'#4e672a'}} >here.</HashLink>,
      img: '/Icons/3x/Number=4.svg'
    },
  ]);
  const [slidesDE, setslidesDE] = useState([
    {
      text: "Werde Testnutzer*in der mobilen Ladestation.",
      text1: 'Registriere dich',
      index: 1,
      title: "Registrierung",
      link: <HashLink to={'/#heroSection'} onClick={handleClick} style={{color:'#4e672a'}}>hier.</HashLink>,
      img: '/Icons/3x/Number=1.svg'
    },
    {
      text: "Kaufe deine RFID-Karte für eine einmalige Zahlung von 17,50 Euro.",
      text1: 'Diese Karte is 30 Tage lang zum unbegrenzten* Laden gültig.',
      text2: '* In dieser Testphase ist die Ladesäule einmal pro Woche verfügbar.',
      index: 2,
      title: "Erwerb der Greenbox RFID Karte",
      link: "",
      img: '/Icons/3x/Number=2.svg'
    },
    {
      text: "Parke dein E-Auto in dem dafür vorgesehenen Parkplatz an der Ladestation.",
      text1: 'Verwende deine RFID-Karte, um dein E-Auto zu laden.',
      index: 3,
      title: "Ladevorgang",
      link: "",
      img: '/Icons/3x/Number=3.svg'
    },
    {
      text: "Vielen Dank fürs Laden!",
      text1: 'Informiere dich über die Verfügbarkeit der Ladestation für deinen nächsten Ladevorgang ',
      index: 4,
      title: "Nächstes Mal",
      link: <HashLink to={'/#status'} style={{color:'#4e672a'}} >hier.</HashLink>,
      img: '/Icons/3x/Number=4.svg'
    },
  ]);


  const [slideIndex, setslideIndex] = useState(0);

  function DesktopV({ slides, TOS }) {
    return (
      <div>
        {" "}
        <div className="flex-container-how">
          {" "}
          {slides.map((slide, index) => (
            <div className="midgreen howbox ML20" key={index}>
              <div className="elastichowbox">
                {" "}
                <div className="whitecircle darkgreen">
                  <div ><img src={`/Icons/3x/Number=${slide.index}.svg`}/> </div>{" "}
                </div>
                <div className="DesktopSquareTitle"> {slide.title} </div>
                <div className="DesktopSquareText"> {slide.text} <br /> {slide.text1} {slide.link}<br />{slide.text2}</div>{" "}
              </div>
            </div>
          ))}
        </div>{" "}
        <div className="DesktopTOS ML40"> {TOS} </div>{" "}
      </div>
    );
  }

  function MobileCenter({ slides }) {
    return (
      <div className="midgreen howboxMobile ">
        {" "}
        <div className="elastichowbox">
          <div className="whitecircle darkgreen">
            <div> <img src={`/Icons/3x/Number=${slides.at(slideIndex).index}.svg`}/> </div>
          </div>
          <div className="MobileSquareTitle">{slides.at(slideIndex).title} </div>
          <div className="MobileSquareText">{slides.at(slideIndex).text} <br /> {slides.at(slideIndex).text1} {slides.at(slideIndex).link}<br /> {slides.at(slideIndex).text2}</div>{" "}
        </div>
      </div>
    );
  }

  function MobileV({ slides}) {
    return (
      <div>
        {" "}
        <div className="flex-container1">
          <div
            className="noHighLight mobileArrow"
            onClick={() => {
              MutateIndex("-");
            }}
          >
            {" "}
            <img src="/Icons/3x/arrowlarrow.svg" alt="Icon 1" />{" "}
          </div>
          <MobileCenter slides={slides} />
          <div
            className="noHighLight mobileArrow"
            onClick={() => {
              MutateIndex("+");
            }}
          >
            {" "}
            <img src="/Icons/3x/arrowrarrow.svg" alt="Icon 1" />{" "}
          </div>
        </div>
        {/* <div className="MobileTOS ML40"> {TOS} </div> */}
      </div>
    );
  }

  function MutateIndex(Action) {
    if (Action === "+") {
      if (slideIndex === 3) {
        setslideIndex(0);
      } else {
        setslideIndex(slideIndex + 1);
      }
    }
    if (Action === "-") {
      if (slideIndex === 0) {
        setslideIndex(3);
      } else {
        setslideIndex(slideIndex - 1);
      }
    }
  }
  function isMobile() {
    if (window.innerWidth < 1000) {
      return true;
    }
    return false;
  }
  function getExactVer() {
    if (language === "EN" && isMobile() === true) {
      return "ENM";
    }
    if (language === "EN" && isMobile() === false) {
      return "ENW";
    }
    if (language === "DE" && isMobile() === true) {
      return "DEM";
    }
    if (language === "DE" && isMobile() === false) {
      return "DEW";
    }
  }

  return (
    <div style={{display:'block', alignItems:'center'}}>
      {getExactVer() === "ENM" && (
        <div style={{paddingTop:'64px', paddingBottom:'64px'}}>
          {" "}
          <div className="MobileTitle "> How does it work? </div>{" "}
          <MobileV  slides={slidesEN} />{" "}
        </div>
      )}
      {getExactVer() === "ENW" && (
        <div style={{padding:'64px'}} >
          {" "}
          <div  className="DesktopTitle centertext"> How does it work? </div>{" "}
          <DesktopV  slides={slidesEN} />
          </div>
      )}
      {getExactVer() === "DEM" && (
        <div style={{paddingTop:'64px', paddingBottom:'64px'}}>
          {" "}
          <div className="MobileTitle "> Wie funktioniert’s?</div>{" "}
          <MobileV slides={slidesDE} />{" "}
        </div>
      )}
      {getExactVer() === "DEW" && (
        <div style={{padding:'64px'}}>
          {" "}
          <div className="DesktopTitle centertext"> Wie funktioniert’s?</div>{" "}
          <DesktopV style={{margin:'64px'}} slides={slidesDE}  />{" "}
        </div>
      )}
    </div>
  );
}
